
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,

  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { setParems } from "../../redux/Campaign";
import DaynmicApicall from "../../utils/function";

export default function BotScript(props) {
  console.log("MENUUUU:: ", props)
  const navigate = useNavigate();
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const [tableData, setTableData] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [pageData, setpageData] = useState([]);
  const dispatch = useDispatch();
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };

  // async function pageInfo() {
  //   await DaynmicApicall(`/appuser/getcomponetbyid/${props.activemenu.keypointer}/${props.activemenu.componentid}`, '', 'get', userInfo.data[0].UserToken).then((data) => {
  //     setpageData(data[0].DATA);
  //   })
  // }
  // useEffect(async () => {
  //   await pageInfo();
  // }, []);
  async function getMenuList() {
    await DaynmicApicall(`mcrmdlr/getbotscriptmaster/`, '', 'get', userInfo.data[0].UserToken).then((res) => {
      setTableData(res);
    })
  }
  async function deleteComponent(e, compInfo) {
    e.preventDefault();
    compInfo.mode = "DELETE";
    const updatePromise = await DaynmicApicall(`appuser/managemenumaster/`, Header, 'post')
    toast.success(updatePromise.message)
    getMenuList();
  }
  const columns = [
    { field: "camp_id", headerName: "Campaign Id", minWidth: 100, flex: 1 },
    { field: "camp_name", headerName: "Campaign Name", minWidth: 150, flex: 1 },
    { field: "dgl", headerName: "DGL", minWidth: 100, flex: 1 },
    { field: "voicecode", headerName: "Voice Code ", minWidth: 80, flex: 1 },
    { field: "lan", headerName: "Language", minWidth: 100, flex: 1 },
    { field: "voice_txt", headerName: "Voice text ", minWidth: 180, flex: 1 },
    { field: "greet_active", headerName: "Greet Active ", minWidth: 80, flex: 1 },
    { field: "greet_dgl", headerName: "Greet DGL ", minWidth: 130, flex: 1 },
    { field: "greet_script", headerName: "Greet Script ", minWidth: 280, flex: 1 },
    {
      field: "active",
      headerName: "Status",
      width: 220,
      flex: 1,
      renderCell: (params) =>
        params.value === 1 ? (
          <Chip label="Active" color="success" size="small" />
        ) : <Chip label="InActive" color="warning" size="small" />
    },
    {
      field: "Action",
      type: "actions",
      headerName: "Action",
      minwidth: 20,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={(e) => {
            dispatch(
              setParems({
                data: {
                  CAMP_ID: params.row.script_id,
                  action: "UPDATE",
                  url: "managebotscript",
                  comp: params.row,
                },
              })
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={(e) => {
            deleteComponent(e, params.row);
          }}
          showInMenu
        />,
      ],
    },
  ];
  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }
  const onFilterChange = React.useCallback(async (filterModel) => {
    if (
      filterModel?.items?.[0]?.value &&
      filterModel?.items?.[0]?.value.length > 0
    ) {
      setFilterColumn(filterModel?.items?.[0]?.columnField);
      setFilterValue(filterModel?.items?.[0]?.value);
    }
  }, []);
  useEffect(async () => {
    await getMenuList();
  }, []);
  return (
    <>
      <>
        <div className="container-fluid py-4 bg ">
          <div className="row">
            <div className="card col-xl-12 bg-dark">
              <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                <span className="text-left text-light ">Master Bot Script</span>
                <span className="text-right">
                  <button
                    type="btn"
                    className="btn btn-success btn-sm float-right"
                    onClick={() => {
                      dispatch(
                        setParems({ data: { action: "INSERT", url: "managebotscript" } })
                      );
                    }}
                  >
                    Add Bot Script
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-body border-light shadow">
          <div className="table-settings mb-4">
            <div className="my-2">
              <DataGrid
                QuickFilterIcon
                getRowId={(r) => r.script_id}
                rows={tableData}
                columns={columns}
                onRowEditCommit
                pageSize={10}
                rowsPerPageOptions={[10, 25, 25, 50, 100]}
                onFilterModelChange={onFilterChange}
                autoHeight={true}
                className="bg-white"
                components={{
                  Toolbar: CustomToolbar,
                }}
                density="compact"
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
}
