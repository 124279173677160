import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import DaynmicApicall, { ManageEventLog } from "../../utils/function";




const SideBar = ({ children, props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [sideNavbarMenus, setSideNavbarMenus] = useState("");
  async function getSideManuInfo() {
    await DaynmicApicall(
      `/appuser/menus/${userInfo.data[0].userid}/${userInfo.data[0].usergroup}`, "","get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        setSideNavbarMenus(res);
      })
      .catch((error) => {
        toast?.error(
          error?.response?.message ??
          error?.message ??
          "OOPs, Something went wrong."
        );
      });
  }

  useEffect(async () => {
    await getSideManuInfo();
  }, [])




  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };




  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "450px" : "75px",
            position: "sticky",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}

        >
          <div className="top_section display-flex text-white text-center justifiContent-space-between">
            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="log text-white"
                >
                  <img className="rounded"
                    src="http://cdnlib.a10s.in/cdndata/veeda.png"
                    alt=""
                    style={{ height: "70px" }}
                  />
                </motion.h1>
              )}
            </AnimatePresence>
          </div>
          <section className="routes">
            {sideNavbarMenus ? sideNavbarMenus?.map((route, index) => {
              if (route.submenu) {
                return (
                  <SidebarMenu key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }
              return (
                <NavLink
                  key={index}
                >
                  <div className="icon">
                    {/* <img src={route.imageurl} alt="" /> */}
                  </div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className=" link_text"
                      >
                        {route?.campname}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            }
            ) : ""}
          </section>

        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
}

export default SideBar;
