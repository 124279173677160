/*
'-------------------------------------------------------------------------------------------------------------------- 
'Project Name		  :  Call Center CRM UI   |   Purpose          :  CRM & Dialer                    |    
'Created By		    :  Harshit Dubey        |   Change Request No:                                  |
'Creation Date 	  :  22-Jul-2022		      |   Description      :                                  |
'Changed By		    :  Harshit Dubey	      |   Change Date 	   :  22-AUG-2022                     |
'Revision History	:  No Change            |                                                       |
'Name				      :  Harshit Dubey        |   Date             :  22-AUG-2022                     |                  
'----------------------------------------------------------------------------------------------------------------------			
'----------------------------------------------------------------------------------------------------------------------*/

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import Loading from "./components/Loading";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from "./redux/store/store";


let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        {/* <Suspense fallback={<Loading />}>
          <App />
        </Suspense> */}
        <PersistGate loading={<Loading />} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);


reportWebVitals();
