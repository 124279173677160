import * as React from 'react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import DaynmicApicall from "../../utils/function";
import "./style.css";
import BlinkingInfo from '../BlinkingInfo';

export default function DialerSidebar(props) {
    const [expanded, setExpanded] = useState(false);
    const { userInfo } = useSelector((state) => state?.user?.value);
    const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
    const [callPtp, setCallPtp] = useState("");
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    async function getCallPtp(agentid, currenthour) {
        await DaynmicApicall(`mcrmdlr/getagentcallptptargetdata/${agentid}/${parseInt(new Date().getHours() + "00")}`, '', 'get', userInfo.data[0].UserToken)
            .then(async (res) => {
                console.log("Res: ", res)
                await setCallPtp(res[0]);

            })
            .catch((error) => {
                console.log("ERROR", error)
                toast.error(
                    error.response.data.message ??
                    error.message ?? "OOPs, Something went wrong."
                )
            });
    }
    // useEffect(async () => {
    //     await getCallPtp();

    // }, [])
    return (
        <div className="container m-3" >
            <div className="row mx-2">
            {/* <BlinkingInfo information="This is blinking information" /> */}
                {/* <div id="ptpcall" className="col-md card mt-3 m-0">
                    <div className="row m-0 ">
                        <ul className="nav nav-pills nav-fill flex-column text-center  flex-sm-row">
                            <li className="nav-item me-sm-2 m-0">
                                <span className="text-left  fw-bold    mb-md-0 card-title pt-1 align-middle" style={{ fontSize: "0.9rem" }}>
                                    Notification
                                </span>
                            </li>
                        </ul>
                        <ul className="nav nav-pills nav-fill flex-column text-center  flex-sm-row m-0">
                            <li className="nav-item me-sm-2 m-0">
                                <span className="text-left fw-bold card-title pt-3 align-middle" style={{ fontSize: "0.9rem" }}>
                                   Team Lead
                                </span>
                            </li>
                        </ul>

                    </div>
                </div> */}
            </div>
           
        </div>
    );
}

