/*
'-------------------------------------------------------------------------------------------------------------------- 
'Module Name		  :  Dialer CRM Dashboard |   Purpose          :  Manage Every functionlty on CRM AND Dialer|    
'Created By		    :  Harshit Dubey        |   Change Request No:                                  |
'Creation Date 	  :  29-Jul-2022		      |   Description      :                                  |
'Changed By		    :  Harshit Dubey	      |   Change Date 	   :  15-SEP-2022                     |
'Revision History	:  No Change            |                                                       |
'Name				      :  Harshit Dubey        |   Date             :  15-AUG-2022                     |                  
'----------------------------------------------------------------------------------------------------------------------			
'---------------------------------- */

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardNav from "../../components/DashboardNav";
import SideBar from "./DashboardSidebar";
import DashboardHome from "./DashboardHome";
import DashboardFooter from "./DashboardFooter";
import EditeUser from "../ManageUser/EditeUser";
import EditUI from "../ManageUIComponent/EditUI";
import DashboardAreaNav from "./DashboardAreaNav";
import Resetpassword from "../auth/ResetPassword";
import AddMenu from "../ManageMenuMaster/AddMenu";
import ChangePassword from "../auth/ChangePassword";
import AddEditRes from "../ManageResponce/AddEditRes";
import EditScripts from "../ManageScripts/EditScripts";
import { setApiReferesher, setCampaignDetail } from "../../redux/Campaign";
import EditGlobal from "../ManageGlobaldailer/EditGlobal";
import AddParameter from "../ManageParameter/AddParameter";
import ManageScripts from "../ManageScripts/ManageScripts";
import EditCampaigns from "../ManageCampaigns/EditCampaigns";
import ManageUserMaster from "../ManageUser/ManageUserMaster";
import ManegeCampaigns from "../ManageCampaigns/ManageCampaigns";
import ManageParameter from "../ManageParameter/ManageParameter";
import CrmHomePage from "../../components/CrmServices/CrmHomePage";
import CampaignGroupMenuMap from "../Mapping/CampaignsGroupMenuMap";
import FileUpload from "../../components/formValidation/FileUpload";
import ManageMenuMaster from "../ManageMenuMaster/ManageMenuMaster";
import ManageResponceMaster from "../ManageResponce/ManageResponceMaster";
import MasterGlobalDailer from "../ManageGlobaldailer/MasterGlobalDailer";
import MapComanyBranchDepartment from "../Mapping/MapComanyBranchDepartment";
import ManageUIComponentMaster from "../ManageUIComponent/ManageUIComponentMaster";
import MasterDialerQueue from "../ManageDialerQueue/MasterDailerQueue";
import DaynmicApicall, {
  ManageEventLog,
  checkValidateJSON,
} from "../../utils/function";
import { setComponentDetail } from "../../redux/Campaign";
import toast from "react-hot-toast";
import AddMenuqueue from "../ManageDialerQueue/AddMenuqueue";
import DataUpload from "../DataUpload/DataUpload";
import ManageResponseCamp from "../ManageResponseCamp/ManageResponseCamp";
import ManageBotDialerData from "../BotAndDilaerData/ManageBotDialerData";
import socket from "../../utils/socket";
import api from "../../utils/api";
import Dialer from "../../components/Dialer/Dialer";
import "./App.css";
import Draggable from "react-draggable";
import DialerDashboard from "./Dialer/DialerDashboard";
import DataAllocation from "../DataAllocation/DataAllocation";
import ManageBotScript from "../ManageBotScriptMaster/ManageBotScript";
import BotScript from "../ManageBotScriptMaster/BotScript";
import HolidayCalender from "../ManageHolidayCalender/HolidayCalender";
import AddEditHoliday from "../ManageHolidayCalender/AddEdittHoliday";
import AddBotParam from "../ManageBotParam/BotParam";
import ManageBotParam from "../ManageBotParam/ManageBotParam";
import { setdialerInfo } from "../../redux/Dialer";
import ManageCallRecording from "../ManageCallRecording/ManageCallRecording";
import Reporting from "../ManageReporting/Reporting";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ManageDataPreprationForCamp from "../ManageDataPreprationForCamp/ManageDataPreprationForCamp";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [isSocketConnected, setSocketConnected] = useState();
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { activeMenu, paremsData, apiReferesher } = useSelector((state) => state.campaign);
  const [getFormStatus, setFormStatus] = useState(false);
  const { page } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  async function getDialerServer() {
    let dlrserver = await f_getConnectedCamp("ALL", userInfo.data[0].userid);
    let allqueue;
    allqueue = dlrserver?.map((data) => {
      return {
        queue_name: data.campname,
        queue_number: data.queue_name,
      };
    });
    let agentSIPConfig = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      wp_config: {
        auto_answer: "true",
        queue_login_code: "*40",
        queue_logout_code: "*41",
      },
      wp_sip_config: {
        sip_username: userInfo.data[0]?.dlrAgentId,
        wss_server: dlrserver[0]?.wss_server_path, // dialer server websocket address/port
        sip_password: userInfo.data[0]?.sippassword,
        sip_server: dlrserver[0]?.dlr_server_ip,
      },
      wp_queue_list:  allqueue 
    };
    socket.emit("crmdialerdata", agentSIPConfig, (data) => {
      console.log("Agent configraction event: ", data);
    });
  }

  const f_getConnectedCamp = async (campid, userid) => {
    const camp = await api.get(`appuser/getcampbycampid/${campid}/${userid}`, {
      headers: {
        Authorization: userInfo.data[0].UserToken,
      },
    });
    return camp.data.data;
  };

  async function socketConnection() {
    socket.on("respfromdialer", async (data) => {
      switch (data.action) {
        case "get_agent_config":
          getDialerServer();
          break;
        case "call_state":
          socket.on("respfromdialer", async (data) => {
            if (
              data.event_data.call_direction === "incoming" ||
              (data.event_data.call_direction === "outgoing" &&
                userInfo.data[0].usergroup !== "AGENT")
            ) {
              localStorage.setItem(
                "currentcall",
                JSON.stringify(data.event_data)
              );
              dispatch(setdialerInfo(data.event_data));
            }
            let checkvalidjson = await checkValidateJSON(
              data?.event_data?.crm_data
            );
            if (
              data?.event_data?.crm_data &&
              data?.event_data?.call_status === "ringing" &&
              data.event_data.agent_token == userInfo.data[0]?.dlrAgentId
            ) {
              // data.event_data.crm_data = JSON.parse(data?.event_data?.crm_data)
              localStorage.setItem(
                "currentcall",
                JSON.stringify(data.event_data)
              );
              dispatch(setdialerInfo(data.event_data));
            }
          });
          break;
        default:
      }

      socket.on("disconnect", (data) => {
        console.log("socket connection: ", data);
      });
    });
  }
  useEffect(() => {
    var Connection = socket.connect("");
    setSocketConnected(Connection.connected);

    socket.on("connection", (socket) => {
      console.log("socket connection: ", socket);
    });
  }, []);
  useEffect(() => {
    socket.emit(
      "join",
      {
        userid: "C" + userInfo.data[0]?.dlrAgentId,
        agent_token: `${userInfo.data[0]?.dlrAgentId}`,
      },
      (error) => {
        if (error) {
          console.log("Error: ", error);
        }
      }
    );
    return () => {
      socket.disconnect();
      socket.off();
    };
  }, []);

  useEffect(() => {
    (async () => {
      await socketConnection();
      if (userInfo.data[0].usergroup !== "AGENT") {
        await setCampdata();
        await setComponent();
      }
    })();
  }, [apiReferesher.referesherApi]);

  async function setCampdata() {
    await DaynmicApicall(
      `/appuser/campmaster`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        dispatch(setCampaignDetail({ data: res }));
        dispatch(setApiReferesher({}));
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.message ??
          error.message ??
          "OOPs, Something went wrong."
        );
      });
  }
  async function setComponent() {
    await DaynmicApicall(
      `/appuser/getcomponentlist/ALL`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        dispatch(setComponentDetail({ data: res }));
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
          error.message ??
          "OOPs, Something went wrong."
        );
      });
  }
  const pages = {
    MNU_DASHBOART: <DashboardHome activemenu={activeMenu} />,
    MNU_RESET_PWD: <Resetpassword activemenu={activeMenu} />,
    MNU_UPLOAD_FILE: <FileUpload activemenu={activeMenu} />,
    MNU_HOLIDAY_UPD: <DashboardHome activemenu={activeMenu} />,
    MNU_CHANGE_PWD: <ChangePassword activemenu={activeMenu} />,
    MNU_CRUD_MENU: <ManageMenuMaster activemenu={activeMenu} />,
    MNU_MANAGE_CAMP: <ManegeCampaigns activemenu={activeMenu} />,
    MNU_MANAGE_SCRIPTS: <ManageScripts activemenu={activeMenu} />,
    MNU_CREATE_USER: <ManageUserMaster activemenu={activeMenu} />,
    MNU_MANAGE_PARAMETER: <ManageParameter activemenu={activeMenu} />,
    MNU_GLOBAL_DIALER: <MasterGlobalDailer activemenu={activeMenu} />,
    MNU_CBD_MAP: <MapComanyBranchDepartment activemenu={activeMenu} />,
    CAMP_GROUP_MENU_MAP: <CampaignGroupMenuMap activemenu={activeMenu} />,
    MNU_MANAGE_RESPONSE: <ManageResponceMaster activemenu={activeMenu} />,
    MNU_CRUD_UICOMP: <ManageUIComponentMaster activemenu={activeMenu} />,
    MNU_CAMP_RESP_MAP: <ManageResponseCamp activemenu={activeMenu} />,
    MNU_MANAGE_DIALER_Q: <MasterDialerQueue activemenu={activeMenu} />,
    MNU_DATA_UPLOAD: <DataUpload activemenu={activeMenu} />,
    DLR_BOT_DATA: <ManageBotDialerData activemenu={activeMenu} />,
    MNU_DATA_ALLO: <DataAllocation activemenu={activeMenu} />,
    MNU_DIALER_DASH: <DialerDashboard activemenu={activeMenu} />,
    MNU_BOT_SCRIPT: <BotScript activemenu={activeMenu} />,
    MNU_HOLI_CALENDER: <HolidayCalender activemenu={activeMenu} />,
    MNU_BOT_PARAM: <ManageBotParam activemenu={activeMenu} />,
    MNU_CALL_RECORD: <ManageCallRecording activemenu={activeMenu} />,
    MANAGE_REPORTING: <Reporting activemenu={activeMenu} />,
    CAMP_DATA_PREPRATION: <ManageDataPreprationForCamp activemenu={activeMenu} />
  };
  const subpages = {
    manageui: <EditUI data={{ paremsData, activeMenu }} />,
    addmenu: <AddMenu data={{ paremsData, activeMenu }} />,
    manageuser: <EditeUser data={{ paremsData, activeMenu }} />,
    addresponse: <AddEditRes data={{ paremsData, activeMenu }} />,
    masterglobal: <EditGlobal data={{ paremsData, activeMenu }} />,
    managecamp: <EditCampaigns data={{ paremsData, activeMenu }} />,
    managescripts: <EditScripts data={{ paremsData, activeMenu }} />,
    managatsparameter: <AddParameter data={{ paremsData, activeMenu }} />,
    managedialerq: <AddMenuqueue data={{ paremsData, activeMenu }} />,
    managebotscript: <ManageBotScript data={{ paremsData, activeMenu }} />,
    addeditholiday: <AddEditHoliday data={{ paremsData, activeMenu }} />,
    addbotparam: <AddBotParam data={{ paremsData, activeMenu }} />,
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (param, data) => {
    setSnackbarContent(data);
    setSnackbarOpen(true);
  };
  function openForm(value) {
    document.getElementById("myForm").style.display = value ? "block" : "none";
  }

  return (
    <div className="container-fulid m-0 b-0">
      {userInfo.data[0].usergroup !== "AGENT" ? (
        <div className="">
          <SideBar>
            <DashboardNav />
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <DashboardAreaNav />
                  {subpages[paremsData.url] ? (
                    subpages[paremsData.url]
                  ) : pages?.[page] ? (
                    pages[page]
                  ) : (
                    <DashboardHome
                      handleSnackbarOpen={handleSnackbarOpen}
                      snackbarOpen={snackbarOpen}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarContent={setSnackbarContent}
                    />
                  )}
                </div>
              </div>
            </div>
          </SideBar>
          <DashboardFooter />
          <div>
            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
                {snackbarContent}
              </Alert>
            </Snackbar>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <CrmHomePage />
        </div>
      )}
      <Draggable className="m-0 b-0">
        <div draggable="true" ondragstart="drag(event)" id="drag1">
          <div className="form-popup" id="myForm">
            <div className="" style={{ marginTop: "-250%" }}>
              <section
                className="phoneView"
                style={{ overflowY: "scroll", overflowX: "hidden" }}
              >
                <Dialer />
              </section>
            </div>
          </div>
        </div>
      </Draggable>
      <Draggable>
        <div className="position-fixed bottom-0 end-0 mb-3">
          <button
            type="button"
            class="btn btn-dark btn-circle btn-xl"
            style={{ height: "60px", width: "60px" }}
            onClick={(e) => {
              setFormStatus(!getFormStatus);
              openForm(getFormStatus);
            }}
          >
            <PhoneAndroidIcon />
          </button>
        </div>
      </Draggable>
    </div>
  );
}
