import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Formik, Form } from "formik";
import { MultiSelect } from "../../formValidation/MultiSelect";
import { useSelector } from "react-redux";
import api from "../../../utils/api";
import toast from "react-hot-toast";
import DaynmicApicall from "../../../utils/function";

const Sms = (props) => {
  let { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  const [scripttext, setScripttext] = useState("");
  const [pageData, setPageData] = useState('');
  const [templates, setTemplate] = useState([])
  const [smsAuthTOken, setSmsAuthTOken] = useState('')
  let { crmsaveandexit } = useSelector((state) => state?.dialer);

  async function pageInfo() {
    try {
      const data = await DaynmicApicall(`/appuser/getcomponetbyid/${props?.userInfo?.campinfo?.keypointer}/73`, '', 'get', userInfo.data[0].UserToken);
      setPageData(data[0].DATA);
    } catch (error) {
      console.log("ERROR: ", error)
      toast.error(
        error.response?.message ??
        error.message ??
        "OOPs, Something went wrong."
      );
      throw error; // rethrow the error to stop the execution chain
    }
  }

  async function fetchData() {
    try {
      console.log("pageData?.tempCampid?.campid::: ", pageData?.tempCampid)
      const { custinfo, campinfo } = props.userInfo
      let Tempcampid = custinfo.ref_cuid ? custinfo?.ref_cuid.slice(0,3) : JSON.parse(JSON.parse(localStorage.currentcall).crm_data).campid

      
        const res = await DaynmicApicall(`/multimedia/getsmsauth/${Tempcampid}`, '', 'get', UserToken);
        const { user_auth_api, userid, campid, user_password } = res;
        const data = await api.post(user_auth_api, { userid, campid, password: user_password });

        let temp = data?.data?.data?.emplates?.map((item) => ({
          value: item.templateid,
          label: item.templatename,
          ...item
        }));
        setTemplate(temp);
        setSmsAuthTOken({ ...data?.data?.data });
      

    } catch (error) {
      console.error("Error fetching SMS auth data:", error);
      // setError("Error fetching SMS auth data");
      throw error; // rethrow the error to stop the execution chain
    } finally {
      // setLoading(false);
    }
  }

  useEffect(() => {
    pageInfo()
      .then(() => fetchData())
      .catch((error) => {
        // Handle errors from either pageInfo or fetchData
        console.error("Error in useEffect:", error);
      });
  }, []);


  const onSubmit = async (values) => {
    const apiData = {
      "custid": crmsaveandexit?.campid + crmsaveandexit?.cust_id,
      "campid": crmsaveandexit?.campid,
      "templateid": values?.smstemplate.value,
      "phone": crmsaveandexit?.dialer_connected_ph,
      "content": values.smstemplate?.tempbody,
      "tempvariables": values.smstemplate?.tempvariables,
      "conditionValue": "flash-message",
      "apiid": "1",
      "sessionid": crmsaveandexit?.dialer_session_id,
      "agentid": crmsaveandexit?.contact_by,
      smsAuth: smsAuthTOken?.token
    }

    const Info = api.post(`multimedia/smsCreation`, apiData, {
      headers: { Authorization: UserToken },
    })
    toast
      .promise(Info, {
        success: (Info) => {
          return Info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((data) => {
      })

  };

  return (
    <>

      {
        // Render the form when both pageData and templates are available
        pageData && <Formik
          initialValues={{
            smstemplate: "",
          }}
          // validationSchema={validate}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <div className="container">
              <Form>
                <div className="row">
                  <div className="col-md-8 mb-3">
                    <MultiSelect
                      className="m-0 p-0"
                      label={pageData.text1.label}
                      name={pageData.text1.name}
                      tooltip={pageData.text1.tooltip}
                      placeholder={pageData.text1.placeholder}
                      value={formik.values.smstemplate}
                      isMulti={false}
                      formik={formik}
                      options={templates}
                      onChange={async (value) => {
                        formik.setFieldValue("smstemplate", value);
                        await setScripttext(value.tempbody);
                      }}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <div className="form-control-sm d-flex align-items-end">
                      <button type="submit" className="btn btn-primary p-2 m-2">
                        {pageData?.button?.label}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="overflow-auto ml-md-5 mt-md-3">
                      {scripttext && (
                        <span className="text-muted">
                          "{scripttext.length > 0 ? scripttext : "No Script Available"}"
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      }
    </>
  );
};

export default Sms;





























// import React, { useEffect, useState } from "react";
// import { Formik, Form } from "formik";
// import { MultiSelect } from "../../formValidation/MultiSelect";
// import { useSelector } from "react-redux";
// import api from "../../../utils/api";
// import toast from "react-hot-toast";
// import DaynmicApicall from "../../../utils/function";

// export default function Sms() {
//   let { userInfo } = useSelector((state) => state?.user?.value);
//   const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
//   const [templates, setTemplate] = useState([])
//   const pageData = {
//     text1: {
//       label: "Select Template",
//       name: "smstemplate",
//       placeholder: "Select Tamplate",
//       tooltip: "Select Tamplate For User",
//     },
//     text2: {
//       label: "Select Language",
//       name: "sendlanguage",
//       placeholder: "Select Language",
//       tooltip: "Select Language For User",
//     },
//     text3: {
//       row: "5",
//       name: "arearow",
//       placeholder: "Template Show",
//       tooltip: "Show Selected Template",
//       type: "text",
//     },
//     text4: {
//       label: "Mobile Number",
//       name: "mobil",
//       placeholder: "Enter Mobile Number",
//       tooltip: "Enter Mobile Number Here..",
//       type: "text",
//     },
//     button: {
//       label: "Send sms",
//     },
//     cancelButton: {
//       label: "cancel",
//     },
//   };

//   useEffect(async () => {

//     await DaynmicApicall(`/mcrmdlr/getsmsauth/111`, '', 'get', userInfo.data[0].UserToken).then(async (res) => {
//       console.log("GETSMSCALLLLLLL:::: ", res)
//       const Info = api.post(res.user_auth_api, {
//         "userid": res.userid,
//         "campid": res.campid,
//         "password": res.user_password
//       })
//         .then((data) => {
//           console.log("Data:: ", data?.data?.data?.emplates)
//           let temp = data?.data?.data?.emplates?.map((item) => {
//             return {
//               value: item.templateid,
//               label: item.templatename,
//               ...item
//             };
//           });
//           setTemplate(temp)
//         })
//     })



//   }, [])


//   const onSubmit = async (values) => {

//     console.log("values:: ", values)


//   }
//   return (
//     <Formik
//       initialValues={{
//         smstemplate: "",
//       }}
//       // validationSchema={validate}
//       onSubmit={onSubmit}
//     >
//       {(formik) => (
//         <div className="card card-body text-dark shadow-sm ">
//           <Form>
//             <div className="row">
//               <MultiSelect
//                 label={pageData.text1.label}
//                 name={pageData.text1.name}
//                 tooltip={pageData.text1.tooltip}
//                 placeholder={pageData.text1.placeholder}
//                 value={formik.values.smstemplate}
//                 isMulti={false}
//                 formik={formik}
//                 options={templates}
//                 onChange={(value) =>
//                   formik.setFieldValue("smstemplate", value)
//                 }
//               />
//               <div className="col-md-4 mt-3">
//                 <div className="form-control-sm">
//                   <button type="submit" className="btn btn-primary p-2 m-2">
//                     {pageData?.button?.label}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </Form>
//         </div>
//       )}
//     </Formik>
//   );
// }


