import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
    crmwebphone: {
        "queueStatus": "",
        "pauseStatus": "",
        "setdialeCall": "",
        "dialerHangupStatus": false,
        "dialerCallHangup" : ""
    }
}

export const webphoneSlice = createSlice({
    name: "webphone",
    initialState: initialValue,
    reducers: {
        setCrmWebphone: (state, action) => {
            state.crmwebphone[Object.entries(action.payload)[0][0]] = Object.entries(action.payload)[0][1];
        },
        removeCrmWebphone: (state, action) => {
            state.crmwebphone = initialValue.crmwebphone;
        },
    }
})

export const { setCrmWebphone, removeCrmWebphone } = webphoneSlice.actions;
export default webphoneSlice.reducer;
