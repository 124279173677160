import { React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSaveandExit } from '../../redux/Dialer';
import { checkValidateJSON, getdetails, pageInfoJson } from '../../utils/function';
import DashboardAreaNav from '../../pages/dashboard/DashboardAreaNav';
import AgentScript from '../../components/CrmServices/AgentScript';
import CustomerSearchPage from './searchpage/CustomerSearchPage';
import AgetDashboard from '../../pages/dashboard/AgetDashboard';
import DailerSidebar from '../Dialer/DailerSidebar';
import DaynmicApicall from '../../utils/function';
import CardDetails from './CardDetails';
import AgentAction from './AgentAction';
import socket from '../../utils/socket';
import api from '../../utils/api';
import './Style.css';


export default function CrmHomePage(props) {
    let { userInfo } = useSelector((state) => state?.user?.value);
    const [customerDetail, setCustomerDetail] = useState('');
    const [customerSearch, setCustomerSearch] = useState("");
    const [connectedCamp, setConnectedCamp] = useState('');
    const [pageData, setpageData] = useState([]);
    const [dialerdata, setDialerdata] = useState('');
    const dispatch = useDispatch()
    const heandelDialerCustomer = (customer) => setCustomerSearch(customer);
    const heandelDialerdata = (dialer) => {
        setDialerdata(dialer)
        if(!dialer) {
            setCustomerDetail('');
            setConnectedCamp('');
        }
    }

    const f_getConnectedCamp = async (campid, userid) => {
        const camp = await api.get(`appuser/getcampbycampid/${campid}/${userid}`, {
            headers: {
                Authorization: userInfo.data[0].UserToken
            }
        })
        return camp.data.data
    }

    async function getCustomer(custid) {
        if (custid) {
            const customer = await api.get(`mcrmdlr/getcustomer/${custid}`, {
                headers: {
                    Authorization: userInfo.data[0].UserToken
                }
            })
            dispatch(setSaveandExit({
                contact_no: customer.data.data[0].contact_no,
            }))
            await setCustomerDetail(customer.data.data[0])
            return customer.data.data[0]
        }
    }
    async function dialerscrmlog(data) {
        if (data.call_status === 'answered') {
            await api.post("/mcrmdlr/managedialercrminput/", data,
                {
                    headers: {
                        Authorization: userInfo.data[0].UserToken
                    }
                }).then(async (res) => { });
        }
    }

    useEffect(() => {
        (async () => {
            await socketConnection();
        })()
    }, [customerSearch])

    async function socketConnection() {
            socket.on('respfromdialer', async (data) => {
                switch (data.action) {
                    case "call_state":
                        socket.on('respfromdialer', async (data) => {
                            let checkvalidjson = await checkValidateJSON(data?.event_data?.crm_data)
                            if (data?.event_data?.crm_data && data?.event_data?.call_status === "answered") {
                                data.event_data.crm_data = JSON.parse(data?.event_data?.crm_data)
                                await crmhomepageprerequisite(data)
                            }
                        })
                        break;
                    default:
                    // cccccccc
                }
            })
        if (customerSearch) {
            await crmhomepageprerequisite(customerSearch)
        }
    }

    async function gettlno(logdata) {
        await DaynmicApicall(`appuser/getprocesstlid/${logdata.campid}`, '', 'get', userInfo.data[0].UserToken).then((data) => {
            let val = {
                cust_id: logdata?.custid?.slice(3),
                campid: logdata.custid.slice(0,3),
                contact_by: userInfo.data[0]?.userid,
                dialer_connected_ph: logdata?.phone,
                tlno: data?.tlid,
                tl: data.tlname,
                call_mode: logdata?.callmode,
                dialer_session_id: logdata?.sessionid,
            }
            Object.entries(val).map((data) => {
                let aa = `{"${data[0]}": "${data[1]}"}`
                dispatch(setSaveandExit(JSON.parse(aa)))
            })
        })
    }
    async function crmhomepageprerequisite(data) {
        let getcamp
        let dialerresp = data.event_data.crm_data
        if (data.event_data.agent_token == userInfo.data[0]?.dlrAgentId && dialerresp.custid && dialerresp.phone) {
            let logdata = {
                custid: dialerresp.custid,
                campid: dialerresp.custid?.length > 0 ? dialerresp.custid?.slice(0, 3) : dialerresp.campid,
                agentid: data.event_data.agent_token,
                phone: dialerresp.phone,
                dialerrespose: data.event_data.call_direction,
                clientid: dialerresp.clientid,
                callmode: dialerresp.callmode,
                // callmode: data.call_direction,
                sessionid: data.event_data.call_session_id,
                notifyurl: window.location.origin + window.location.pathname,
                call_status: data.event_data.call_status,
                call_start: data.event_data.call_start,
                call_stop: data.event_data.call_stop,
                event_data: data.event_data,
                dialeruuid: dialerresp.dialeruuid,       // ****************
                recordingfile: dialerresp.recordingfile, // ****************
                text1: dialerresp.text1,
                active: "Y",
                action_name: "INSERT",
            }
            dialerscrmlog(logdata)
            setDialerdata(data)
            await getCustomer(logdata.custid);
            if (logdata.campid) {
                getcamp = await f_getConnectedCamp(logdata.campid, userInfo.data[0].userid);
            }
            logdata.userid = userInfo.data[0]?.userid
            await gettlno(logdata)
            await getdetails(logdata)
            await setConnectedCamp(logdata.campid ? getcamp[0] : "");
            dispatch(setSaveandExit({
                prod_id: logdata?.text1,
            }))
            await pageInfoJson(getcamp[0].keypointer, 25, userInfo.data[0].UserToken).then((data) => {
                setpageData(data)
            })
        }
    }

    let parentTOChields = { custinfo: customerDetail, campinfo: connectedCamp }

    return (
        <>
            {
                pageData && (
                    <div className="container-fulid">
                        <>
                            <div  className="row bg-dark mt-1 font-weight-bold rounded" style={{ color: "white" }}>
                                {/* Header Section */}
                                <span className="text-left mt-3 col-sm-12 col-md-12  col-lg-3" id={`${connectedCamp ? 'header' : ''}`}>{connectedCamp ? connectedCamp?.campname : "Agent Dashboard"}</span>
                                <span id ="wlcmtext1" className="text-center mt-3 col-sm-12 col-md-12 col-lg-4">Welcome {userInfo.data[0].username}
                                    <span className='text-success ml-1'>
                                        ({userInfo.data[0].userid})
                                    </span>
                                </span>
                                <span id ="wlcmtext" className="text-right mt-3 col-sm-12 col-md-12  col-lg-4">{(new Date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                <div id ="profileimg" className='col-sm-12 col-md-12 col-lg-12 col-xl-1'><DashboardAreaNav Value={""} /></div>
                            </div>
                            <div className='row'>
                                {
                                    dialerdata ? dialerdata && (
                                       ( connectedCamp && customerDetail )? connectedCamp && (
                                            <div className='col-sm-12 col-md-12 col-lg-9'>
                                                <div className="row">
                                                    {/* Agent Script */}
                                                    <AgentScript data={parentTOChields} />
                                                    <CardDetails data={parentTOChields} aftersave={heandelDialerdata} />
                                                    {/* Agent Action button */}
                                                    <AgentAction data={parentTOChields} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='col-sm-12 col-md-12 col-lg-9'>
                                                <CustomerSearchPage
                                                    searchTOCrm={heandelDialerCustomer}
                                                    aftersave={heandelDialerdata}
                                                    dialerdata={dialerdata}
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <div className="col-sm-12 col-md-6 col-lg-9">
                                            <AgetDashboard />
                                        </div>
                                    )
                                }
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <DailerSidebar />
                                </div>
                            </div>
                            {/*</div> */}
                        </>
                    </div>
                )
            }
        </>
    );
}






