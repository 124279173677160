import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { setParems } from "../../redux/Campaign";
import DaynmicApicall from "../../utils/function";
import ManageBotScript from "../ManageBotScriptMaster/ManageBotScript";

export default function ManageBotParam(props) {
  const navigate = useNavigate();
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const [tableData, setTableData] = useState([]);
  const [filterColumn, setFilterColumn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [campList, setCampList] = useState([]);
  // const [pageData, setpageData] = useState([]);
  const dispatch = useDispatch();
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };

  async function getBotParams() {
    await DaynmicApicall(`appuser/getbotparam`, '', 'get', userInfo.data[0].UserToken).then((res) => {
      setTableData(res);
    })
  }

  const columns = [
    { field: "rec_path", headerName: "Recording Url", minWidth: 70, flex: 1 },
    { field: "notify_url", headerName: "Notify Url", minWidth: 70, flex: 1 },
    { field: "botapi_ip", headerName: "Bot Transfer API", minWidth: 70, flex: 1, },
    { field: "block_api_ip", headerName: "Data Block API", minWidth: 70, flex: 1, },
    { field: "recordingapi", headerName: "Recording API", minWidth: 70, flex: 1, },
    { field: "dlrpostapi", headerName: "Dialer API", minWidth: 70, flex: 1, },
    {
      field: "active", headerName: "Status", minWidth: 50, flex: 1,
      renderCell: (params) =>
        params.value === 1 ? (
          <Chip label="Active" color="success" size="small" />
        ) : (
          <Chip label="InActive" color="warning" size="small" />
        )
    },
    {
      field: "bot_rec_status", headerName: "Bot Recording Status", minwidth: 50, flex: 1,
      renderCell: (params) =>
        params.value === 1 ? (
          <Chip label="Active" color="success" size="small" />
        ) : (
          <Chip label="InActive" color="warning" size="small" />
        )
    },
    {
      field: "Action", type: "actions", headerName: "Action", width: 80, flex: 1, getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={(e) => {
            dispatch(
              setParems({
                data: {
                  CAMP_ID: params.row.CAMP_ID,
                  action: "UPDATE",
                  url: "addbotparam",
                  comp: params.row,
                },
              })
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={(e) => {

          }}
          showInMenu
        />,
      ],
    },
  ];
  function CustomToolbar() {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    );
  }


  useEffect(async () => {
    await getBotParams()
  }, []);


  const pageData = { title: "Manage Bot Params", dropDownName: "Campaign Name", dropBox: { "label": "Campaign Name", "placeholder": "Select Campaign", "tooltip": "Select Your Campaign", "type": "text" }, addBtn: { "label": "Add Bot Param", "placeholder": " Bot Param", "tooltip": "Click Here To Add Bot Param", "type": "text" } }

  return (
    <>
      {pageData && (
        <>
          <div className="container-fluid py-4 bg ">
            <div className="row">
              <div className="card col-xl-12 bg-dark">
                <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                  <span className="text-left text-light ">{pageData?.title}</span>
                  <span className="text-right">
                    <button
                      type="btn"
                      className="btn btn-success btn-sm float-right"
                      onClick={() => {
                        dispatch(
                          setParems({
                            data: { action: "INSERT", url: "addbotparam" },
                          })
                        );
                      }}
                    >
                      {pageData?.addBtn?.label}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-body border-light shadow">
            <div className="table-settings mb-4">
              <div className="my-2">
                <DataGrid
                  // loading={loadingData}
                  getRowId={(r) => r.sno}
                  rows={tableData}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 25, 50, 100]}
                  // checkboxSelection
                  // paginationMode="server"
                  // onFilterModelChange={onFilterChange}
                  // onPageChange={handlePageChange}
                  autoHeight={true}
                  className="bg-white"
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  density="compact"
                />
              </div>
            </div>

          </div>
        </>

      )}
      {/* <ManageBotScript /> */}
    </>

  );
}

