import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { useDispatch } from "react-redux";
import SignIn from "./pages/auth/SignIn";
import Dashboard from "./pages/dashboard/Dashboard";
import Error404 from "./pages/error/Error404";
import Error500 from "./pages/error/Error500";
import { login, logout } from "./redux/User";
import ChangePassword from "./pages/auth/ChangePassword";
import SendWebphoneDataTOCRM from "./pages/SendWebphoneDataTOCRM";
import jwt_decode from "jwt-decode";
import api from "./utils/api";
import { useNavigate } from "react-router-dom";

const Router = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const userI = JSON.parse(localStorage.getItem("support_user"));
  const isLoggedIn = userI?.isLoggedIn ?? false;
  dispatch(login({ ...userI }));


  useEffect(() => {
    if (isLoggedIn) {
      document.onkeydown = function (e) {
        if (e.keyCode == 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "R".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
          return false;
        }
      };
      document
        .getElementsByTagName("html")[0]
        .setAttribute("oncontextmenu", "return false");
     
    }
  }, []);

  useEffect(() => {
    function preventRefresh() {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        alert('Refresh is not allowed on this page.');
        return false;
      }
    }

    window.addEventListener('unload', preventRefresh);

    return () => {
      window.removeEventListener('unload', preventRefresh);
    };
  }, []);

  // useEffect(() => {
  //   function preventRefresh(e) {
  //     e.preventDefault();
  //     e.returnValue = '';
  //   }

  //   window.addEventListener('beforeunload', preventRefresh);

  //   return () => {
  //     window.removeEventListener('beforeunload', preventRefresh);
  //   };
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn to="/" />} />
        <Route exact path="/error404" element={<Error404 />} />
        <Route exact path="/error500" element={<Error500 />} />
        <Route
          exact
          path="/dashboard"
          element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />}
        >
          <Route path=":page" element={<Dashboard />} />
          <Route path="edituser" element={<Dashboard />}>
            <Route path=":userid" element={<Dashboard />} />
          </Route>
        </Route>
        <Route exact path="/changepassword" element={<ChangePassword />} />
      </Routes>
      <div>
        <Toaster position="top-right" toastOptions={{
          success: {
            style: {
              background: '#05A677',
              color: "#fff"
            },
          },
          info: {
            style: {
              background: '#0948B3',
              color: "#fff"
            },
          },
          error: {
            style: {
              background: '#FA5252',
              color: "#fff"
            },
          },
        }} />
      </div>
    </>
  );
};

export default Router;
