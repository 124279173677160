import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ManageEventLog } from "../../utils/function";
import { useDispatch, useSelector } from "react-redux";
import { setSaveandExit } from "../../redux/Dialer";
import DespositionCompo from "./Desposition/DespositionCompo";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function BasicTabs(props) {
  let { userInfo } = useSelector((state) => state?.user?.value);
  const dispatch = useDispatch();
  // const [value, setValue] = React.useState(0);
  const [value, setValue] = React.useState(
    props.subscript.subscript && props.subscript.subscript.length > 0 ? 0 : -1
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var ReplaceObjeWithScript = {
    Customer_Surname: props.customer.policy_owner,
    policy_name: props.customer.policy,
    Agent_Name: userInfo.data[0]?.userid,
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          value={value}
          onChange={handleChange}
        >
          {props.subscript.subscript &&
            props.subscript.subscript.map((s, i) => (
              <Tab
                onClick={async (e) => {
                  dispatch(
                    setSaveandExit({
                      sub_resp_code: s.resp_type === "PRI" ? "NA" : s.resp_code,
                    })
                  );
                  let actionlog = {
                    userid: userInfo.data[0].userid,
                    custid: props.customer.custid.slice(3),
                    campid: props.campinfo.campid,
                    createdby: userInfo.data[0].userid,
                    eventname: "OnClick",
                    actionname: `${s.resp_code} Sub-Disposition Called`,
                    actioncate: "SUB-D",
                    action_name: "INSERT",
                    resp_code:
                      s.parent_resp_code === "NA"
                        ? s.resp_code
                        : s.parent_resp_code,
                    sub_resp_code: s.resp_code,
                  };
                  await ManageEventLog(actionlog);
                }}
                label={s.script_name}
                {...a11yProps({ i })}
                key={i}
              />
            ))}
        </Tabs>
      </Box>
      {props.subscript.subscript.map((s, i) => {
        return (
          <TabPanel value={value} index={i} key={i}>
            {s.script_temp_body.replace(
              /Customer_Surname|Policy_Name/gi,
              function (matched) {
                return ReplaceObjeWithScript[matched];
              }
            )}
            {s.component_id !== "9999" &&
              (() => {
                switch (s.resp_code) {
                  default:
                    return (
                      <DespositionCompo
                        componentid={s.component_id}
                        customer={{ ...props.customer, ...s }}
                        campinfo={props.campinfo}
                        onClose={props.onClose}
                      />
                    );
                }
              })()}
          </TabPanel>
        );
      })}
    </Box>
  );
}
