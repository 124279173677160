import { Form, Formik } from 'formik';
import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { MultiSelect } from '../../components/formValidation/MultiSelect';
import { useSelector } from 'react-redux';
import DaynmicApicall from '../../utils/function';
import toast from 'react-hot-toast';
import { Col, Row } from 'react-bootstrap';

export default function ManageServerConfig(props) {
    const { userInfo } = useSelector((state) => state?.user?.value);
    const [getAgentdata, setAgentData] = useState([]);
    const [getSocketServer, setSocketServer] = useState([]);

    async function getAgentingormation() {
        await DaynmicApicall(`mcrmdlr/getagenttodaysummary/${props.rowdata.agentid}`, '', 'get', userInfo.data[0].UserToken).then(async (data) => {
            console.log("DDDDDDDDDDDDDD: ", data)
            setAgentData(data[0])
        })
    };
    async function getSocketServerConfig() {
        await DaynmicApicall(`prm/getParamdata/ALL/WSS-SERVER-URL`, '', 'get', userInfo.data[0].UserToken).then(async (data) => {
            let bData = [];
            bData = await data.map((item) => {
                return {
                    value: item.paravalue,
                    label: item.paracode,
                };
            });
            setSocketServer(bData)
        })
    };


    useEffect(() => {
        (async () => {
            await getAgentingormation();
            // await getSocketServerConfig()
        })()
    }, [])

    let initial = {
        camp_id: props.rowdata.campid,
        dlr_server_ip: "",
        wss_server_path: "",
    }

    const onSubmit = async (values) => {
        console.log("VALUES", values)
        values.dlr_server_ip = values.dlr_server_ip.value
        values.wss_server_path = values.wss_server_path.value
        // /appuser/manageserveroncrm/

        let Info = await DaynmicApicall(
            `/appuser/manageserveroncrm/`,
            values,
            "post",
            userInfo.data[0].UserToken
        );
        toast.success(Info.message);
    }

    return (
        <>
            <div className="modal fade" id="cardtoggel1" tabindex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered justify-content-center" role="document">
                    <div className="modal-content " style={{ marginTop: "250px" }}>
                        <div className="modal-header bg-dark text-light">
                            <h6 className="text-bold">
                                Agent Report
                            </h6>
                            <button type="button" className="btn-close btn-light" data-bs-dismiss="modal"></button>
                        </div>
                        <div className='col-10 ml-5'>
                            <Row className="ml-2" xs={6} xl={3} >
                                {
                                    getAgentdata && Object.entries(getAgentdata).map((data, i) => (
                                        <Col className="p-1 m-0" index={i}>
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    {data[0].replaceAll("_", " ").charAt(0).toUpperCase() + data[0].replaceAll("_", " ").slice(1)}:
                                                    <span className="badge text-dark col-4">{data[1]}</span>
                                                </li>
                                            </ul>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
