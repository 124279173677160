import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import { TextField } from "../../components/formValidation/TextField";
import { Toggle } from "../../components/formValidation/Toggle";
import { setParems } from "../../redux/Campaign";
import api from "../../utils/api";
import DaynmicApicall from "../../utils/function";

export default function AddBotParam(props) {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state?.user?.value);
    const { campdetail } = useSelector((state) => state.campaign);
    //   const [pageData, setpageData] = useState({});


    //   async function pageInfo() {
    //     await DaynmicApicall(`/appuser/getcomponetbyid/${props.data.activeMenu.keypointer}/${props.data.activeMenu.subcompid}`, '', 'get', userInfo.data[0].UserToken)
    //       .then((data) => {
    //         setpageData(data[0].DATA);
    //       })
    //   }
    //   useEffect(async () => {
    //     await getResponsemasterlist()
    //     await getScriptType();
    //     getEligible();
    //     getColorcode();
    //     await pageInfo();
    //   }, []);

    let allCamps = [];
    if (campdetail && campdetail.length > 0) {
        allCamps = campdetail.map((item) => {
            return {
                value: item.campid,
                label: item.campname,
            };
        });
    }
    const pageData = {
        list: { heading: " Bot Param", "search": { "label": "Search Here", "style": { "color": "black", "frontweight": "bold" } }, "addButton": { "label": "Add", "tooltip": "Add New Data", "style": { "backgroundColor": "", "color": "" } }, "table": { "headerclr": "white", "headerbgclr": "#a077c9", "colminwidth": "140px" } },
        style: { "color": "#333" },

        "fieldsData": {
            "titleAdd": "Add Bot Parameter", "titleUpdate": "Update Bot Parameter",
            "campaign": { "label": "Campaign", "name": "campaign", "placeholder": "Enter Campaign", "options": "camp" },
            "recording_url": { "label": "Recording Url", "name": "recpath", "placeholder": "Enter Recording Url" },
            "notify_url": { "label": "Notify Url", "name": "notifyurl", "placeholder": "Enter notify url", "type": "text" },
            "pacing": { "label": "Pacing", "name": "pacing", "placeholder": "Pacing", "type": "number" },
            "bot_transfer_api": { "label": "Bot Transfer API", "name": "botapi_ip", "placeholder": "Enter Bot Transfer Api ", "type": "text" },
            "recording_pacing": { "label": "Recording Pacing", "name": "recording_pacing", "placeholder": "Recording Pacing", "type": "number" },
            "block_pacing": { "label": "Data Block Pacing", "name": "block_pacing", "placeholder": "Data Block Pacing", "type": "number" },
            "data_block_api": { "label": "Data Block Api", "name": "block_api_ip", "placeholder": "Enter Data Block Api ", "type": "text" },
            "recording_api": { "label": "Recording Api", "name": "recordingapi", "placeholder": "Enter Recording Api ", "type": "text" },
            "dialer_api": { "label": "Dialer Api", "name": "dlrpostapi", "placeholder": "Enter Dialer Api ", "type": "text" },
            "pipe": { "label": "Pipe", "placeholder": "Enter Pipe", "tooltip": "Enter Pipe", "name": "pipe", "type": "number" },
            "push_pipe": { "label": "Push Pipe", "placeholder": "Enter Push Pipe", "tooltip": "Enter Push Pipe", "name": "push_pipe", "type": "number" },
            "status": { "label": "Status", "placeholder": "Enter Status", "tooltip": "Enter Status", "name": "active", "type": "switch" },
            "bot_recording_status": { "label": "Bot Recording Status", "placeholder": "Enter Bot recording Status", "tooltip": "Enter Bot recording Status", "name": "botrec_status", "type": "switch" },
            "bot_posting_status": { "label": "Bot Posting Status", "placeholder": "Enter Bot Posting Status", "tooltip": "Enter Bot Posting Status", "name": "botpost_status", "type": "switch" },
            "dialer_data_post_status": { "label": "Dialer Data Post Status", "placeholder": "Enter Dialer Data Post Status", "tooltip": "Enter Dialer Data Post Status", "name": "dlrstatus", "type": "switch" },
            savebtn: {
                "type": "submit",
                "label": "Save"
            },
            cancelbtn: {
                "label": "Cancel"
            }
        }
    }

    let action = props?.data?.paremsData.action === "UPDATE";
    let initial = {
        sno: action ? props?.data?.paremsData.comp.sno : "NEW",
        camp_id: action ? props?.data?.paremsData.comp.camp_id : "",
        // campaign: action ? props?.data?.paremsData.comp.campaign_name : "",
        campaign:  action
      ? allCamps?.find((v) => v?.label === props?.data?.paremsData?.comp?.campaign_name)
      : "",
        recpath: action ? props?.data?.paremsData.comp.rec_path : "",
        notifyurl: action ? props?.data?.paremsData.comp.notify_url : "",
        pacing: action ? props?.data?.paremsData.comp.pacing : "",
        botapi_ip: action ? props?.data?.paremsData.comp.botapi_ip : "",
        recording_pacing: action ? props?.data?.paremsData.comp.recording_pacing : "",
        block_pacing: action ? props?.data?.paremsData.comp.block_pacing : "",
        block_api_ip: action ? props?.data?.paremsData.comp.blockapi_ip : "",
        createdby: userInfo.data[0].userid,
        recordingapi: action ? props?.data?.paremsData.comp.recordingapi : "",
        dlrpostapi: action ? props?.data?.paremsData.comp.dlrpostapi : "",
        pipe: action ? props?.data?.paremsData.comp.pipe : "",
        push_pipe: action ? props?.data?.paremsData.comp.push_pipe : "",
        botrec_status:action? (props.data.paremsData.comp.bot_rec_status == 1? true:false):"", 
        botpost_status: action ? (props.data.paremsData.comp.bot_post_status == 1? true:false):"",
        dlrstatus: action ? (props.data.paremsData.comp.dlr_status == 1? true:false):"",
        action_name: action ? "UPDATE" : "INSERT",
        active: action? (props.data.paremsData.comp.active == 1? true:false):1,
       
    };

    const onSubmit = async (values) => {
        console.log(initial, 'initial')
        console.log(values, 'valueeees')
        values.active = values.active === true ? 1 : 0;
        let camp = values.campaign;
        values.campid = camp.value;
        values.campname = camp.label;
        values.botrec_status = values.botrec_status === true ? 1 : 0;
        values.botpost_status = values.botpost_status === true ? 1 : 0;
        values.dlrstatus = values.dlrstatus === true ? 1 : 0;
        values.action_name = props.data.paremsData.action;
        values.createdby = userInfo.data[0].userid

        console.log(values, 'values')

        if (props.data.paremsData.action === "INSERT") {
            console.log(values, 'values')
            const Info =
                await DaynmicApicall(`appuser/botparammaster/`, values, 'post', userInfo.data[0].UserToken)
            toast.success(Info.message)
            dispatch(setParems({ data: "" }));
        } else {
            const Info =
                await DaynmicApicall(`appuser/botparammaster/`, values, 'post', userInfo.data[0].UserToken)
            toast.success(Info.message)
            dispatch(setParems({ data: "" }));
        }
    };

    return (
        <>
            {Object.keys(pageData).length > 0 ? (
                <Formik initialValues={initial} onSubmit={onSubmit}>
                    {(formik) => (
                        <div
                            className="card card-body border-light shadow mb-4"
                            style={pageData?.style}
                        >
                            <h6 className="text-light card-header rounded bg-dark">
                                {" "}
                                {props?.data?.paremsData?.action === "INSERT"
                                    ? pageData.fieldsData?.titleAdd
                                    : pageData.fieldsData?.titleUpdate}
                            </h6>

                            <Form>
                                <div className="row mt-3">
                                    <MultiSelect
                                        title={pageData?.fieldsData?.campaign.label}
                                        data-toggle="tooltip"
                                        label={pageData?.fieldsData?.campaign.label}
                                        value={formik.values.campaign}
                                        name={pageData?.fieldsData?.campaign.name}
                                        placeholder={pageData?.fieldsData?.campaign.placeholder}
                                        isMulti={false}
                                        options={allCamps}
                                        onChange={(value) =>
                                            formik.setFieldValue("campaign", value)
                                        }
                                    />
                                    <TextField
                                     title="Please Enter Recording Url"
                                     data-toggle="tooltip"
                   
                                        label={pageData.fieldsData.recording_url.label}
                                        name={pageData.fieldsData.recording_url.name}
                                        placeholder={pageData.fieldsData.recording_url.placeholder}
                                        type={pageData.fieldsData.recording_url.type}
                                        // defaultValue={props.data.ResponseCode.action === "UPDATE" ? userData?.ResponseCode : null}
                                        disabled={props?.data?.paremsData.action === "UPDATE"}
                                    />
                                    <TextField
                                    title="Please Enter Notify Url"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.notify_url.label}
                                        name={pageData.fieldsData.notify_url.name}
                                        placeholder={pageData.fieldsData.notify_url.placeholder}
                                        type={pageData.fieldsData.notify_url.type}
                                    />
                                    <TextField
                                    title=""
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.pacing.label}
                                        name={pageData.fieldsData.pacing.name}
                                        placeholder={pageData.fieldsData.pacing.placeholder}
                                        type={pageData.fieldsData.pacing.type}
                                    />
                                    <TextField
                                    title="Please Enter Bot Transfer API"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.bot_transfer_api.label}
                                        name={pageData.fieldsData.bot_transfer_api.name}
                                        placeholder={pageData.fieldsData.bot_transfer_api.placeholder}
                                        type={pageData.fieldsData.bot_transfer_api.type}
                                    />
                                    <TextField
                                    title="Please Enter Recording Pacing"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.recording_pacing.label}
                                        name={pageData.fieldsData.recording_pacing.name}
                                        placeholder={pageData.fieldsData.recording_pacing.placeholder}
                                        type={pageData.fieldsData.recording_pacing.type}
                                    />
                                    <TextField
                                    title="Please Enter Data Block Pacing"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.block_pacing.label}
                                        name={pageData.fieldsData.block_pacing.name}
                                        placeholder={pageData.fieldsData.block_pacing.placeholder}
                                        type={pageData.fieldsData.block_pacing.type}
                                    />
                                    <TextField
                                    title="Please Enter Data Block API"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.data_block_api.label}
                                        name={pageData.fieldsData.data_block_api.name}
                                        placeholder={pageData.fieldsData.data_block_api.placeholder}
                                        type={pageData.fieldsData.data_block_api.type}
                                    />
                                    <TextField
                                    title="Please Enter Recording Api"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.recording_api.label}
                                        name={pageData.fieldsData.recording_api.name}
                                        placeholder={pageData.fieldsData.recording_api.placeholder}
                                        type={pageData.fieldsData.recording_api.type}
                                    />
                                    <TextField
                                    title="Please Enter Dialer Api"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.dialer_api.label}
                                        name={pageData.fieldsData.dialer_api.name}
                                        placeholder={pageData.fieldsData.dialer_api.placeholder}
                                        type={pageData.fieldsData.dialer_api.type}
                                    />
                                    <TextField
                                    title="Please Enter Pipe"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.pipe.label}
                                        name={pageData.fieldsData.pipe.name}
                                        placeholder={pageData.fieldsData.pipe.placeholder}
                                        type={pageData.fieldsData.pipe.type}
                                    />
                                    <TextField
                                    title="Please Enter Push Pipe"
                                    data-toggle="tooltip"
                                        label={pageData.fieldsData.push_pipe.label}
                                        name={pageData.fieldsData.push_pipe.name}
                                        placeholder={pageData.fieldsData.push_pipe.placeholder}
                                        type={pageData.fieldsData.push_pipe.type}
                                    />
                                    <div className="row mt-4">
                                        <Toggle

title="Active or De-active"
data-toggle="tooltip"


                                              defaultChecked={formik.values.active}
                                              value={formik.values.active}
                                              onChange={(e) =>
                                                formik.setFieldValue("active", e.target.checked)
                                              }
                                            label={pageData?.fieldsData?.status?.label}
                                            name={pageData?.fieldsData?.status?.name}
                                            placeholder={pageData?.fieldsData?.status?.placeholder}
                                        />
                                        <Toggle

title="Bot Recording Status"
data-toggle="tooltip"
                                        defaultChecked={formik.values.botrec_status}
                                        value={formik.values.botrec_status}

                                        onChange={(e) =>
                                          formik.setFieldValue("botrec_status", e.target.checked)
                                        }
                                            label={pageData?.fieldsData?.bot_recording_status?.label}
                                            name={pageData?.fieldsData?.bot_recording_status?.name}
                                            placeholder={pageData?.fieldsData?.bot_recording_status?.placeholder}
                                            type={pageData?.fieldsData?.bot_recording_status?.type}
                                        //   defaultChecked={formik.values.leadstatus}
                                        //   value={formik.values.leadstatus}
                                        //   onChange={(e) =>
                                        //     formik.setFieldValue("leadstatus", e.target.checked)
                                        //   }
                                        />
                                        <Toggle
                                        title="Bot Posting Status"
                                        data-toggle="tooltip"
                                            label={pageData?.fieldsData?.bot_posting_status?.label}
                                            name={pageData?.fieldsData?.bot_posting_status?.name}
                                            type={pageData?.fieldsData?.bot_posting_status?.type}
                                            defaultChecked={formik.values.botpost_status}
                                            value={formik.values.botpost_status}
                                            onChange={(e) =>
                                            formik.setFieldValue("botpost_status", e.target.checked)
                                          }
                                        />
                                        <Toggle
                                        title="Dialer Data Post Status"
                                        data-toggle="tooltip"
                                            label={pageData?.fieldsData?.dialer_data_post_status?.label}
                                            name={pageData?.fieldsData?.dialer_data_post_status?.name}
                                            type={pageData?.fieldsData?.dialer_data_post_status?.type}
                                          defaultChecked={formik.values.dlrstatus}
                                          value={formik.values.dlrstatus}
                                          onChange={(e) =>
                                            formik.setFieldValue("dlrstatus", e.target.checked)
                                          }
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end mt-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm m-2"
                                    >
                                        {pageData.fieldsData.savebtn.label}
                                    </button>
                                    <Link
                                        to="MNU_BOT_PARAM"
                                        className="btn btn-primary btn-sm m-2"
                                        onClick={(e) => {
                                            dispatch(
                                                setParems({ data: "MNU_BOT_PARAM" })
                                            );
                                        }}
                                    >
                                        {pageData.fieldsData.cancelbtn.label}
                                    </Link>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            ) : (
                ""
            )}
        </>
    );
}