import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import api from "../../utils/api";
import { useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Stack } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DataAllocationPopup from "./DataAllocationPopup";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import DaynmicApicall from "../../utils/function";
import { toast } from "react-hot-toast";

export default function DataAllocation(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { campdetail } = useSelector((state) => state.campaign);
  const [searchdata, setSearchdata] = useState([]);
  const [pageData, setPageData] = useState("");
  const [pageSize, setPageSize] = useState(50);
  // const [condition, setCondition] = useState(50);
  const [dataCount, setTotalDataCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  let allCamps = [];
  if (campdetail && campdetail.length > 0) {
    allCamps = campdetail.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
      };
    });
  }

  const handleOpenDialog = (content) => {
    setDialogContent({ ...dialogContent, ...content });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePostToBot = () => {
    handleOpenDialog({
      type: "BOT",
    });
  };

  const handlePostToDialer = () => {
    handleOpenDialog({
      type: "DIALER",
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [initialData, setInitialData] = useState({
    campid: "",
    quearyFields: [
      {
        Fields: "",
        Operator: "",
        value: "",
      },
    ],
  });

  // const pageData = {
  //     "filters": [
  //         {
  //             "value": "age",
  //             "label": "Age",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "food_habit",
  //             "label": "Food Habit",
  //             "type": "multi",
  //             "fieldtype": "multi",
  //             "multi": true,
  //             "option": [
  //                 { "value": "Vegetarian", "label": "Vegetarian" },
  //                 { "value": "Non-Vegetarian", "label": "Non-Vegetarian" }
  //             ]
  //         },
  //         {
  //             "value": "campid",
  //             "label": "Campigin",
  //             "type": "multi",
  //             "fieldtype": "multi",
  //             "multi": true,
  //             "option": [
  //                 { "value": "808", "label": "VEEDA" },
  //                 { "value": "809", "label": "MEHSHANA" }
  //             ]
  //         },
  //         {
  //             "value": "weight",
  //             "label": "Weight",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "lot_date",
  //             "label": "Data Upload Date",
  //             "type": "text",
  //             "fieldtype": "date"
  //         },
  //         {
  //             "value": "bmi",
  //             "label": "BMI",
  //             "type": "text",
  //             "fieldtype": "text"
  //         },
  //         {
  //             "value": "localcity",
  //             "label": "Local City",
  //             "type": "multi",
  //             "fieldtype": "multi",
  //             "multi": true,
  //             "option": [
  //                 { "value": "Ahamdabad", "label": "Ahamdabad" },
  //                 { "value": "Surat", "label": "Surat" }
  //             ]
  //         },
  //         {
  //             "value": "marital_status",
  //             "label": "Marital",
  //             "type": "multi",
  //             "fieldtype": "multi",
  //             "multi": true,
  //             "option": [
  //                 { "value": "Married", "label": "Married" },
  //                 { "value": "Unmarried", "label": "Unmarried" }
  //             ]

  //         },
  //         {
  //             "value": "gender",
  //             "label": "Gender",
  //             "type": "multi",
  //             "fieldtype": "multi",
  //             "multi": true,
  //             "option": [
  //                 { "value": "Male", "label": "Male" },
  //                 { "value": "Female", "label": "Female" }
  //             ]
  //         },
  //         {
  //             "value": "nexteligibilitydate",
  //             "label": "Last Eligible Date",
  //             "type": "text",
  //             "fieldtype": "date"
  //         },
  //         {
  //             "value": "lastsampledate",
  //             "label": "Last Sample Date",
  //             "type": "text",
  //             "fieldtype": "date"
  //         },
  //         {
  //             "value": "last_haemoglobin_result",
  //             "label": "Last Haemoglobin Result",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "reported_count",
  //             "label": "Reported Count",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "participated_count",
  //             "label": "Participated Count",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "literacyconsent",
  //             "label": "Literacy Consent",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "last_project_no",
  //             "label": "Last Project No",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "consentlanguage",
  //             "label": "Consent Language",
  //             "type": "multi",
  //             "fieldtype": "multi",
  //             "multi": true,
  //             "option": [
  //                 {
  //                     "label": "English",
  //                     "value": "English"
  //                 },
  //                 {
  //                     "label": "English literate",
  //                     "value": "English literate"
  //                 },
  //                 {
  //                     "label": "Gujarati",
  //                     "value": "Gujarati"
  //                 },
  //                 {
  //                     "label": "Gujarati illiterate",
  //                     "value": "Gujarati illiterate"
  //                 },
  //                 {
  //                     "label": "Gujarati literate",
  //                     "value": "Gujarati literate"
  //                 },
  //                 {
  //                     "label": "Hindi",
  //                     "value": "Hindi"
  //                 },
  //                 {
  //                     "label": "Hindi illiterate",
  //                     "value": "Hindi illiterate"
  //                 },
  //                 {
  //                     "label": "Hindi literate",
  //                     "value": "Hindi literate"
  //                 }
  //             ]
  //         },
  //         {
  //             "value": "contactnumber",
  //             "label": "Contact Number",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "lastresponse",
  //             "label": "Last Response",
  //             "type": "text",
  //             "fieldtype": "number"
  //         },
  //         {
  //             "value": "height",
  //             "label": "Height",
  //             "type": "text",
  //             "fieldtype": "number"
  //         }
  //     ],
  //     "Operator": [
  //         {
  //             "value": "<=",
  //             "label": "<="
  //         },
  //         {
  //             "value": "=",
  //             "label": "="
  //         },
  //         {
  //             "value": ">=",
  //             "label": ">="
  //         },
  //         {
  //             "value": "in",
  //             "label": "in"
  //         },
  //     ],
  //     "columns": [
  //         { field: "cust_id", headerName: "Customer ID", width: 150, className: 'text-center' },
  //         { field: "volunteer_no", headerName: "Volunteer No", width: 150 },
  //         { field: "volunteer_name", headerName: "Volunteer Name", width: 150 },
  //         { field: "age", headerName: "Age", width: 100 },
  //         { field: "bmi", headerName: "BMI", width: 100 },
  //         { field: "height", headerName: "Height", width: 100 },
  //         { field: "weight", headerName: "Weight", width: 100 },
  //         { field: "cust_language", headerName: "Language", width: 150 },
  //         { field: "contact_no", headerName: "Phone Number", width: 150 },
  //         { field: "gender", headerName: "Gender", width: 150 }

  //     ],
  //     "buttons": {
  //         "addfilter": {
  //             "label": "Add more filter",
  //             "tooltip": "Click on this button to add more filters",
  //             "variant": "contained",
  //             "color": "primary",
  //             "size": "small",
  //             "type": "submit"
  //         },
  //         "removefilter": {
  //             "label": "Search",
  //             "tooltip": "Click on this button to remove filter",
  //             "variant": "contained",
  //             "color": "error",
  //             "size": "small",
  //             "type": "submit"
  //         },
  //         "searchdata": {
  //             "label": "Search",
  //             "tooltip": "Click on search button to search a data",
  //             "variant": "contained",
  //             "color": "success",
  //             "size": "small",
  //             "type": "submit"
  //         },
  //         "reset": {
  //             "label": "Reset form",
  //             "tooltip": "Reset the Above from",
  //             "variant": "contained",
  //             "color": "success",
  //             "size": "small",
  //             "type": "submit"
  //         },
  //         "posttoDialer": {
  //             "label": "POST TO DIALER",
  //             "tooltip": "Click on this button for search data post to Dialer",
  //             "variant": "contained",
  //             "color": "success",
  //             "size": "small",
  //             "type": "submit",
  //             "hidden": false

  //         },
  //         "posttobot": {
  //             "label": "Post to bot",
  //             "tooltip": "Click on this button for search data post to bot",
  //             "variant": "contained",
  //             "color": "success",
  //             "size": "small",
  //             "type": "submit",
  //             "hidden": true

  //         }
  //     }
  // }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.activemenu.keypointer}/${props.activemenu.componentid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((data) => {
        setPageData(data[0].DATA);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }

  useEffect(async () => {
    await pageInfo();
  }, []);

  const onSubmit = async (values, actions) => {
    setSearchdata([]);
    setTotalDataCount(0);
    const searchField = [...values.quearyFields];
    searchField.push({
      Fields: {
        value: "campid",
        label: "Campigin",
        type: "multi",
        fieldtype: "multi",
        multi: true,
        option: [
          {
            value: "808",
            label: "VEEDA",
          },
          {
            value: "809",
            label: "MEHSHANA",
          },
        ],
      },
      Operator: "in",
      value: [values.campid.value],
    });

    const Info = api.post(
      "/mcrmdlr/getdataallocation",
      { quearyFields: searchField },
      { headers: { Authorization: userInfo.data[0].UserToken } }
    );
    toast
      .promise(Info, {
        loading: "Searching Data.",
        success: (resolvedInfo) => resolvedInfo.data.message,
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then(async (Info) => {
        if (Info.data.data.length > 0 && Info.data.data[0].row_count > 0) {
          let scriptval = {
            dgl: { value: 244, label: "TRAIL7" },
            userid: userInfo.data[0].userid,
            query: Info.data.condition,
            campid: values.campid.value,
          };
          setSearchdata(Info.data.data);
          setTotalDataCount(Info.data.data[0].row_count);

          setDialogContent({
            campid: values.campid.value,
            condition: Info.data.condition,
          });

          // await api.post("services/managebotscriptcreation/", scriptval, {
          //   headers: { Authorization: userInfo.data[0].UserToken },
          // });
        }
      });

    // setCondition(Info.data.condition);
  };

  function CustomToolbar() {
    return (
      <Stack direction="row" spacing={0.5} m={1}>
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      </Stack>
    );
  }

  const validationSchema = Yup.object().shape({
    campid: Yup.object().required("Camp Id is required"),
    // quearyFields: Yup.array().of(
    //     Yup.object().shape({
    //         Fields: Yup.object().required("Select Fields is required"),
    //         Operator: Yup.string().required("Operator is required"),
    //         value: Yup.mixed().when('Fields', {
    //             is: (field) => field?.type === 'text',
    //             then: Yup.string().required("Value is required"),
    //             otherwise: Yup.array().when('Fields', {
    //                 is: (field) => field?.type === 'multi',
    //                 then: Yup.array().min(1, "Select at least one value"),
    //                 otherwise: Yup.mixed()
    //             })
    //         })
    //     })
    // )
  });

  return (
    <>
      {pageData && (
        <div>
          <div>
            <Box className="card bg-dark">
              <div className="col-xl-12 ">
                <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                  <span className="text-left text-light">
                    Calling Data Management
                  </span>
                  <span className="text-right"></span>
                </div>
              </div>
            </Box>

            <Box className="card mt-3 p-2">
              <Formik
                initialValues={initialData}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <>
                    <Box className="card-body">
                      <Grid
                        container
                        spacing={2}
                        sx={{ my: 1, display: "flex" }}
                      >
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <Select
                            options={allCamps}
                            placeholder="Campaigin Id"
                            isSearchable
                            onChange={(selectedOption) =>
                              formik.setFieldValue(
                                `campid`,
                                selectedOption || ""
                              )
                            }
                          />
                          <ErrorMessage
                            name="campid"
                            component="div"
                            className="text-danger"
                          />
                        </FormControl>
                      </Grid>
                      {formik.values.quearyFields.map((exp, index) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ my: 1, display: "flex" }}
                          key={index}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Select
                              options={pageData.filters}
                              placeholder="Select Fields"
                              isSearchable
                              onChange={(selectedOption) =>
                                formik.setFieldValue(
                                  `quearyFields[${index}].Fields`,
                                  selectedOption || ""
                                )
                              }
                            />
                            <ErrorMessage
                              name={`quearyFields[${index}].Fields`}
                              component="div"
                              className="error"
                            />
                          </FormControl>

                          <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <Select
                              options={pageData.Operator}
                              placeholder="Operator"
                              isSearchable
                              onChange={(selectedOption) =>
                                formik.setFieldValue(
                                  `quearyFields[${index}].Operator`,
                                  selectedOption?.value || ""
                                )
                              }
                            />
                            {/* <ErrorMessage name={`quearyFields[${index}].Operator`} component="div" className="error" /> */}
                          </FormControl>

                          {formik.values.quearyFields[index].Fields.type ===
                            "text" && (
                            <>
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <Tooltip
                                  title="{pageData.buttons.searchdata.tooltip}"
                                  placement="top-start"
                                >
                                  <TextField
                                    label="Value"
                                    variant={
                                      formik.values.quearyFields[index].Fields
                                        .fieldtype !== "date"
                                        ? "standard"
                                        : "filled"
                                    }
                                    fullWidth
                                    type={
                                      formik.values.quearyFields[index].Fields
                                        .fieldtype
                                    }
                                    name={`quearyFields[${index}].value`}
                                    onChange={formik.handleChange}
                                    value={exp.value}
                                  />
                                  {/* <ErrorMessage name={`quearyFields[${index}].value`} component="div" className="error" /> */}
                                </Tooltip>
                              </FormControl>
                            </>
                          )}
                          {formik.values.quearyFields[index].Fields.type ===
                            "multi" && (
                            <>
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <Select
                                  options={
                                    formik.values.quearyFields[index].Fields
                                      .option
                                  }
                                  isMulti={
                                    formik.values.quearyFields[index].Fields
                                      .multi
                                  }
                                  placeholder="Select values"
                                  isSearchable
                                  onChange={(selectedOptions) => {
                                    const selectedValues =
                                      selectedOptions.length > 0
                                        ? selectedOptions.map(
                                            (option) => option.value
                                          )
                                        : null;

                                    formik.setFieldValue(
                                      `quearyFields[${index}].value`,
                                      selectedValues || []
                                    );
                                  }}
                                />
                                {/* <ErrorMessage name={`quearyFields[${index}].value`} component="div" className="error" /> */}
                              </FormControl>
                            </>
                          )}

                          {index === formik.values.quearyFields.length - 1 && (
                            <>
                              <Tooltip
                                title={pageData.buttons.addfilter.tooltip}
                                placement="top-start"
                              >
                                <Button
                                  sx={{ m: 1 }}
                                  variant={pageData.buttons.addfilter.variant}
                                  color={pageData.buttons.addfilter.color}
                                  size={pageData.buttons.addfilter.size}
                                  onClick={() =>
                                    formik.setValues({
                                      ...formik.values,
                                      quearyFields: [
                                        ...formik.values.quearyFields,
                                        { Fields: "", Operator: "" },
                                      ],
                                    })
                                  }
                                >
                                  <AddCircleOutlineIcon />
                                  {pageData.buttons.addfilter.label}
                                </Button>
                              </Tooltip>
                              <Tooltip
                                title={pageData.buttons.removefilter.tooltip}
                                placement="top-start"
                              >
                                <Button
                                  sx={{ m: 1 }}
                                  variant={
                                    pageData.buttons.removefilter.variant
                                  }
                                  color={pageData.buttons.removefilter.color}
                                  size={pageData.buttons.removefilter.size}
                                  onClick={() => {
                                    if (formik.values.quearyFields.length > 1) {
                                      const updatedFilters =
                                        formik.values.quearyFields.slice(0, -1);
                                      formik.setValues({
                                        ...formik.values,
                                        quearyFields: updatedFilters,
                                      });
                                    }
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Tooltip
                          title={pageData.buttons.searchdata.tooltip}
                          placement="top-start"
                        >
                          <Button
                            sx={{ m: 1 }}
                            variant={pageData.buttons.searchdata.variant}
                            color={pageData.buttons.searchdata.color}
                            type={pageData.buttons.searchdata.type}
                            onClick={formik.handleSubmit}
                          >
                            {pageData.buttons.searchdata.label}
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={pageData.buttons.reset.tooltip}
                          placement="top-start"
                        >
                          <Button
                            sx={{ m: 1, background: "#444" }}
                            variant={pageData.buttons.reset.variant}
                            color={pageData.buttons.reset.color}
                            type={pageData.buttons.reset.type}
                            onClick={formik.resetForm}
                          >
                            {pageData.buttons.reset.label}
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Box>
                  </>
                )}
              </Formik>

              {searchdata && dataCount > 0 && (
                <div className="my-4">
                  <div className="card card-body border-light shadow">
                    <div className="table-settings mb-4">
                      <>
                        <Grid
                          container
                          spacing={2}
                          sx={{ ml: 0.5, my: 1, display: "flex" }}
                        >
                          <div style={{ flexGrow: 1, textAlign: "left" }}>
                            Count: {dataCount}
                          </div>
                          <Tooltip
                            title={pageData.buttons.posttobot.tooltip}
                            placement="top-start"
                          >
                            <Button
                              sx={{ m: 1, background: "#444" }}
                              hidden={pageData.buttons.posttobot.variant}
                              variant={pageData.buttons.posttobot.variant}
                              color={pageData.buttons.posttobot.color}
                              size={pageData.buttons.posttobot.size}
                              onClick={handlePostToBot}
                            >
                              {pageData.buttons.posttobot.label}
                            </Button>
                          </Tooltip>

                          <Tooltip
                            title={pageData.buttons.posttoDialer.tooltip}
                            placement="top-start"
                          >
                            <Button
                              sx={{ m: 1 }}
                              variant={pageData.buttons.posttoDialer.variant}
                              hidden={pageData.buttons.posttoDialer.hidden}
                              color={pageData.buttons.posttoDialer.color}
                              size={pageData.buttons.posttoDialer.size}
                              onClick={handlePostToDialer}
                            >
                              {pageData.buttons.posttoDialer.label}
                            </Button>
                          </Tooltip>
                        </Grid>

                        <DataAllocationPopup
                          open={openDialog}
                          onClose={handleCloseDialog}
                          content={dialogContent}
                          pageData={pageData.popup}
                        />
                      </>

                      <div className="my-2">
                        <DataGrid
                          getRowId={(r) => r.cuid}
                          rows={searchdata ? searchdata : []}
                          columns={pageData.columns}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          rowsPerPageOptions={[50, 70, 100]}
                          // onFilterModelChange={onFilterChange}
                          autoHeight={true}
                          className="bg-white"
                          components={{
                            Toolbar: CustomToolbar,
                          }}
                          density="compact"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
}
