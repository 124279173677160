import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import * as Yup from "yup";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { BASE_URL } from "../../utils/constent";
import DaynmicApicall from "../../utils/function";

export default function ManageDataUpload(props) {
  const [excelurl, setexcelurl] = useState([]);
  const [success, setSuccess] = useState("");
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { campdetail } = useSelector((state) => state.campaign);

  let allCamps = [];
  var loading = false;

  if (campdetail && campdetail.length > 0) {
    allCamps = campdetail.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
      };
    });
  }

  async function getsampleexcelpath() {
    await DaynmicApicall(`prm/getParamdata/ALL/EXCEL-URL`, '', 'get', userInfo.data[0].UserToken)
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paravalue,
            label: item.paracode,
          };
        });
        setexcelurl(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
          error.message ??
          "OOPs, something went wrong."
        );
      });
  }

  useEffect
  (async () => {
    await getsampleexcelpath();
  }, []);

  console.log(excelurl,'56excelurl')

  const validate = Yup.object().shape({
    campid: Yup.object().required("Camp Id is required"),
    file: Yup.mixed().required('File is required'),
  })

  const initialValues = {
    file: "",
    campid: "",
  };
  const dataupload = (values) => {
    const data = new FormData();
    data.campid = values.campid.value;
    data.append("file", values.file);
    data.append("campid", values.campid.value);
    let info = api
      .post(`/dataupload`, data)
    toast
      .promise(info, {
        success: (info) => {
          return info.data.message;
        },
        error: (err) => {
          return (
            err?.response?.data?.errors ??
            err?.response?.data?.message ??
            err?.message ??
            "OOPs something went wrong."
          );
        },
      })
      .then((res) => {
        setSuccess(res.data.message);
        setTimeout(() => {
          setSuccess("");
        }, 5000);
      })
      .catch((err) => {
        console.log("ERROR", err)
      });
  };

  return (
    <>
      <div>
        <div className="container-fluid py-4 bg ">
          <div className="row">
            <div className="card col-xl-12 bg-dark">
              <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                <span className="text-left text-light ">Data Upload</span>
              </div>
            </div>
          </div>
        </div>
        {
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={dataupload}>
            {(formik) => {
              const imageChange = (e) => {
                const file = e.target.files[0];
                formik.setFieldValue("file", file);
              };
              return (
                <Form>
                  <div className="card">
                    <div className="row mt-1">
                      <div className="col-7">
                        <MultiSelect
                          label="Select Campaign"
                          name="campid"
                          options={allCamps}
                          isMulti={false}
                          onChange={(value) =>
                            formik.setFieldValue("campid", value)
                          }
                        />
                      </div>
                      <div className="col-1 mt-4 mr-4"
                        data-toggle="tooltip"
                        title="Please Choose file only csv formate"
                      >
                        <input style={{ marginLeft: "-300px", height: "42px", width: "270px" }}
                          type="file"
                          onChange={imageChange}
                          // required
                          id="myDropify"
                          className="border"
                        />
                      </div>
                      <div className="col-1 mt-4" style={{ marginLeft: "-100px" }}
                        data-toggle="tooltip"
                        title="Data Upload "
                      >
                        <button className="btn-sm btn-primary p-1 " type="submit">
                          Upload
                        </button>
                      </div>
                      <div className="col-1 mt-4 " style={{ marginLeft: -"20px" }} data-toggle="tooltip"
                        title="Please Download sample data file">
                        {excelurl.length>0 && <a
                          className="dropdown-item"
                          style={{ width: "200px" }}
                          // href={`${BASE_URL}/download/customermaster.csv`}
                          href = {excelurl[0].value}
                          // href="https://veedaapi.a10s.in/api/download/customermaster.csv"
                          // href="http://localhost:5000/api/download/customermaster.csv"
                          target="_blank"
                          rel="noopener noreferrer"
                        >Sample file download</a> }
                      </div>
                    </div>
                  </div>

                </Form>
              );
            }}
          </Formik>
        }
      </div>
    </>
  );
}
