import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarExport,
    gridClasses,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import { Form, Formik } from "formik";
import PageHeader from "../../components/PageHeader";
import DaynmicApicall from "../../utils/function";
import { TextField } from "../../components/formValidation/TextField";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

var url;
export default function ManageCallRecording(props) {
    const { userInfo } = useSelector((state) => state?.user?.value);
    const [campList, setCampList] = useState([]);
    const [searchfount, setSearchfount] = useState(false);
    const [searchdata, setSearchdata] = useState([]);
    const [allocFilter, setAllocFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { campdetail } = useSelector((state) => state.campaign);
    const [tableData, setTableData] = useState([]);
    const [filterColumn, setFilterColumn] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [hide, setHide] = useState("");
    let getApiconstat;

    let allCamps = [];
    if (campdetail && campdetail.length > 0) {
        allCamps = campdetail.map((item) => {
            return {
                value: item.campid,
                label: item.campname,
            };
        });
    }

    let wav = [{
        value: 'wav',
        label: 'wav'
    }];


    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setHide("hidden")
    };

    const saveAll = () => {
        setShowDropdown(!showDropdown);
        setHide("hidden")
    };

    var onSearch = async (values, { resetForm }) => {
        values.camp_id = values.camp_id !== 'NA' ? values.camp_id.value : "NA";
        values.entry_date = { start: values.entry_date_from, end: values.entry_date_to };
        values.phone_no = values.phone_no !== 'NA' ? values.phone_no : "NA";
        values.agent_id = values.agent_id !== 'NA' ? values.agent_id : "NA";
        values.volunteer_no = values.volunteer_no !== 'NA' ? values.volunteer_no : "NA";
        const Info = await DaynmicApicall(`mcrmdlr/getrecordingdata/`, values, 'post', userInfo.data[0].UserToken)
        setSearchfount(true)
        setSearchdata(Info.data.finalresult)
    };

    const save = (row) => {
        const audioUrl = row.recordingfile;
        window.open(row.recordingfile, '_blank');
        console.log(row.recordingfile, 'rec')
    };

    var audio;
    const playrecording = (row) => {
        audio = new Audio(row.recordingfile)
        console.log(row.recordingfile, 'row')
        if (row.recordingfile != "") {

            console.log(audio, 'audio')
            audio.play();
        }

    }

    const pauserecording = (row) => {
        audio.pause();
    }

    async function tableInfo() {
        getApiconstat = `appuser/getallusermapgroup`;
        await DaynmicApicall(`appuser/getallusermapgroup`, '', 'get', userInfo.data[0].UserToken)
            .then((res) => {
                setTableData(res);
            })
    }

    const columns = [
        {
            field: "Action",
            type: "actions",
            headerName: "Action",
            minWidth: 100,
            flex: 1,
            getActions: (params) => [
                <GridActionsCellItem
                    label='Play'
                    icon={<PlayCircleIcon className='text-info' />}
                    onClick={e => {
                        console.log("e.params.row", params.row)
                        playrecording(params.row)
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    label='Pause'
                    icon={<PauseCircleIcon className='text-info' />}
                    onClick={e => pauserecording(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    label="Save"
                    icon={<DownloadForOfflineIcon className='text-info' />}
                    onClick={e => {
                        console.log("e.params.row", params.row)
                        save(params.row)
                    }}
                    showInMenu
                />,
            ],
        },
        {
            field: "phone_no",
            headerName: "Phone Number",
            minWidth: 150,
            flex: 1,
        },
    
        // {
        //     field: "volunteer_no",
        //     headerName: "Client ID",
        //     minWidth: 150,
        //     flex: 1,
        // },
     
        {
            field: "entry_date_time",
            headerName: "Call Date",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "recordingfile",
            headerName: "Recording File",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "last_resp_code",
            headerName: "Resp Code",
            minWidth: 150,
            flex: 1,
        },

        { field: "campname1", headerName: "Camp Name", minWidth: 150, flex: 1 },
        {
            field: "agent_id",
            headerName: "Agent Id",
            minWidth: 100,
            flex: 1,
        },





    ];

    async function getCampaign(e) {
        await DaynmicApicall(`appuser/getcamp/ALL`, '', 'get', userInfo.data[0].UserToken)
            .then((res) => {
                let uniqueCamp = [
                    ...new Map(
                        res?.map((item) => [item["campname"], item])
                    ).values(),
                ];
                let camp = uniqueCamp.map((item) => {
                    return {
                        value: item.campid,
                        label: item.campname,
                    };
                });
                setCampList(camp);
            })

    }

    useEffect(async () => {
        await tableInfo();
        await getCampaign();

    }, []);

    let initial = {
        camp_id: "NA",
        entry_date: "NA",
        entry_date_from: "NA",
        entry_date_to: "NA",
        phone_no: "NA",
        volunteer_no: "NA",
        agent_id: "NA",
        last_resp_code: "NA"
    };

    const PageDat = {
        "title": "Manage Call Recording",
        camp: {
            "label": "Campaign Id",
            "name": "camp_id"
        },
        entry_date_from: {
            "label": "Entry date from",
            "name": "entry_date_from",
            "type": "date",
            "name": "entry_date_from",
            "title": "entry_date_from",
            "placeholder": "Please Enter call Date"

        },
        entry_date_to: {
            "title": "Entry_date_to",
            "label": "entry date to",
            "type": "date",
            "name": "entry_date_to",
            "placeholder": "Please Enter call Date"
        },
        phone_no: {
            "title": "Please Enter Phone Number",
            "label": "Phone Number",
            "type": "text",
            "name": "phone_no",
            "placeholder": "Please Enter Phone Number"
        },

        volunteer_no: {
            "title": "Please Enter Volunteer Number",
            "label": "Volunteer Number",
            "type": "text",
            "name": "volunteer_no",
            "placeholder": "Please Enter Volunteer Number"
        },

        agent_id: {
            "title": "Please Enter Volunteer Number",
            "label": "Volunteer Number",
            "type": "text",
            "name": "agent_id",
            "placeholder": "Please Enter Agent Id"
        },

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function CustomToolbar() {
        return (
            <Stack direction="row" justifyContent="flex-end">
                <GridToolbarContainer className={gridClasses.toolbarContainer}>
                    <GridToolbarExport />
                    <GridToolbarFilterButton />
                </GridToolbarContainer>
            </Stack>
        );
    }

    const onFilterChange = React.useCallback(async (filterModel) => {
        if (
            filterModel?.items?.[0]?.value &&
            filterModel?.items?.[0]?.value.length > 0
        ) {
            setFilterColumn(filterModel?.items?.[0]?.columnField);
            setFilterValue(filterModel?.items?.[0]?.value);
        }
    }, []);

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    return (
        <>
            <PageHeader heading="Manage Call Recording" />
            <div className="card">
                <Formik initialValues={initial} onSubmit={onSearch}>
                    {(formik) => (
                        <Form>
                            <div className="row mt-3">
                                <MultiSelect
                                    label="Campaign Id"
                                    value={formik.values.camp_id}
                                    name="camp_id"
                                    isMulti={false}
                                    formik={formik}
                                    options={allCamps}
                                    onChange={(value) =>
                                        formik.setFieldValue("camp_id", value)
                                    }
                                />
                                <TextField
                                    title="Call Date From"
                                    data-toggle="tooltip"
                                    label="Call Date From"
                                    type="date"
                                    name="entry_date_from"
                                    placeholder="Please Enter Call Date"
                                />
                                <TextField
                                    title="Call Date To"
                                    data-toggle="tooltip"
                                    label="Call Date To"
                                    type="date"
                                    name="entry_date_to"
                                    placeholder="Please Enter Call Date"
                                />
                                <TextField
                                    title="Please Enter Phone Number"
                                    data-toggle="tooltip"
                                    label="Phone Number"
                                    type="text"
                                    name="phone_no"
                                    placeholder="Please Enter Phone Number"
                                />
                                <TextField
                                    title="Please Enter Volunteer Number"
                                    data-toggle="tooltip"
                                    label="Volunteer Number"
                                    type="text"
                                    name="volunteer_no"
                                    placeholder="Please Enter Volunteer Number"
                                />

                                <TextField
                                    title="Please Enter Agent Id"
                                    data-toggle="tooltip"
                                    label="Agent Id"
                                    type="text"
                                    name="agent_id"
                                    placeholder="Please Enter Agent Id"
                                />

                                <TextField
                                    title="Please Enter Response Code"
                                    data-toggle="tooltip"
                                    label="Response Code"
                                    type="text"
                                    name="last_resp_code"
                                    placeholder="Please Enter Response Code"
                                />
                                {/* <TextField
                                            title="Please Enter Replace File"
                                            data-toggle="tooltip"
                                            label="Replace File"
                                            type="text"
                                            name="agentid"
                                            placeholder="please Enter Replace File"
                                        /> */}

                            </div>

                            <div className="d-flex justify-content-end w-100">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-sm m-2"
                                >
                                    Search
                                </button>
                                {/* <button
                                            type="submit"
                                            className="btn btn-primary btn-sm m-2"
                                        >
                                            Save
                                        </button> */}
                                <button type="reset" className="btn btn-primary btn-sm m-2">
                                    Cancel
                                </button>
                            </div>
                        </Form>

                    )}
                </Formik>
                <div className="table-settings mb-4">
                    <div className="my-2">
                        {searchfount && (
                            <div>
                                <div className="card card-body border-light shadow">
                                    <div className="table-settings mb-4">
                                        <div className="my-2">
                                            {
                                                searchdata && <DataGrid
                                                    style={{ overflow: 'hidden', whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                                    title="gstadfggg"
                                                    data-toggle="tooltip"
                                                    getRowId={(row) => generateRandom()}
                                                    rows={searchdata ? searchdata : []}
                                                    columns={columns}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    onFilterModelChange={onFilterChange}
                                                    colSpan={3}
                                                    count={searchdata ? searchdata.length : 0}
                                                    // rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    slotProps={{
                                                        select: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                        actions: {
                                                            showFirstButton: true,
                                                            showLastButton: true,
                                                        },
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    autoHeight={true}
                                                    className="bg-white"
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                    density="compact"
                                                />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>



        </>
    );
}
