import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Formik, ErrorMessage } from "formik";
import api from "../../utils/api";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import * as Yup from "yup";
import DaynmicApicall from "../../utils/function";
import { DialogTitle } from "@mui/material";

const DataAllocationPopup = ({ open, onClose, content, pageData }) => {
    const { userInfo } = useSelector((state) => state?.user?.value);
    const [optionData, setOptionData] = useState("");
    const [projectInfo, setProjectInfo] = useState([]);
    const [displayInfo, setDisplayInfo] = useState(false);
    const [actionType, setActionType] = useState("");
    const [selectedProjectData, setSelectedProjectData] = useState(null);

    async function getProjectInfo() {
        await DaynmicApicall(
            `/mcrmdlr/getproject`,
            "",
            "get",
            userInfo.data[0].UserToken
        )
            .then((data) => {
                const arr = data.map((item) => {
                    return {
                        value: item.project_id,
                        label: item.project_name,
                        ...item,
                    };
                });
                setProjectInfo(arr);
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                toast.error(
                    error.response.message ??
                    error.message ??
                    "OOPs, Something went wrong."
                );
            });
    }

    useEffect(async () => {
        await getProjectInfo();
    }, []);

    async function onSubmitTop(values, actions) {
        try {
            console.log("content:::: ", content)
            const postData = {
                ...values,
                drugcheck: values?.drugTest,
                campaign_id: content.campid,
                condition: content.condition,
            };

            const Info = api.post(
                "/services/managedialerdatatransf",
                postData,
                { headers: { Authorization: userInfo.data[0].UserToken } }
            );
            toast
                .promise(Info, {
                    loading: "Posting data on dialer",
                    success: (resolvedInfo) => resolvedInfo.data.message,
                    error: (err) => {
                        return (
                            err?.response?.data?.errors ??
                            err?.response?.data?.message ??
                            err?.message ??
                            "OOPs something went wrong."
                        );
                    },
                })

            // await toast.promise(Promise.resolve(Info), {
            //     loading: "Processing Data Transfer.",
            //     success: (resolvedInfo) => resolvedInfo.data.message,
            //     error: (err) => {
            //         const errorMessage =
            //             err?.response?.data?.errors ||
            //             err?.response?.data?.message ||
            //             err?.message ||
            //             "OOPs something went wrong.";
            //         throw new Error(errorMessage);
            //     },
            // });
        } catch (error) {
            console.error("Error in onSubmit:", error);
        }
    }

    async function onSubmitBottom(values, actions) {
        try {
            const postData = {
                projectid: values.project_id ? values.project_id : "0",
                projectname: values.project_name,
                startdate: values.project_sdate,
                enddate: values.project_edate,
                project_status: "Y",
                actionname: actionType === "Add" ? "INSERT" : "UPDATE",
                createdby: "ADMIN",
            };

            await DaynmicApicall(
                `/mcrmdlr/manageproject`,
                postData,
                "post",
                userInfo.data[0].UserToken
            )
                .then(async (data) => {
                    if (data.status === "SUCCESS") {
                        await getProjectInfo();
                        setDisplayInfo(false);
                        setActionType("");
                        toast.success(data.message);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                    toast.error(
                        error.response.message ??
                        error.message ??
                        "OOPs, Something went wrong."
                    );
                });
        } catch (error) {
            console.error("Error in onSubmit:", error);
        }
    }

    const onCloseDialog = (event, reason) => {
        if (reason !== "backdropClick") {
            onClose();
            setOptionData("");
            setDisplayInfo(false);
            setActionType("");
        }
        onClose();
        setDisplayInfo(false);
        setActionType("");
        setOptionData("");
    };

    const validationTopSchema = Yup.object({
        retry: Yup.number()
            .min(0, "Retry cannot be negative")
            .required("Retry is required"),
        retry_count: Yup.number()
            .min(0, "Retry Interval cannot be negative")
            .required("Retry Interval is required"),
        priority: Yup.number().required("Priority is required"),
        drugTest: Yup.number().required("Drug Test is required"),
    });

    const validationBottomSchema = Yup.object({
        project_name: Yup.string().required("project_name is required"),
        project_sdate: Yup.date().required("Start Date is required"),
        project_edate: Yup.date().required("End Date is required"),
    });

    return (
        <>
            <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle
                    sx={{ m: 0, pl: 2, pt: 2, pb: 0 }}
                    id="customized-dialog-title"
                >
                    {pageData.header}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={pageData.topData.initial}
                        onSubmit={onSubmitTop}
                        validationSchema={validationTopSchema}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={6}>
                                        <Tooltip
                                            title={pageData.topData.retry.tooltip}
                                            placement="top-start"
                                        >
                                            <TextField
                                                variant="standard"
                                                label={pageData.topData.retry.label}
                                                name={pageData.topData.retry.name}
                                                type={pageData.topData.retry.type}
                                                placeholder={pageData.topData.retry.placeholder}
                                                value={formik.values[pageData.topData.retry.name]}
                                                fullWidth
                                                {...formik.getFieldProps("retry")} // Connect field to Formik
                                            />
                                        </Tooltip>
                                        <ErrorMessage
                                            name="retry"
                                            component="div"
                                            style={{ color: "red", marginTop: "5px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Tooltip
                                            title={pageData.topData.retryinterval.tooltip}
                                            placement="top-start"
                                        >
                                            <TextField
                                                variant="standard"
                                                label={pageData.topData.retryinterval.label}
                                                name={pageData.topData.retryinterval.name}
                                                type={pageData.topData.retryinterval.type}
                                                value={
                                                    formik.values[pageData.topData.retryinterval.name]
                                                }
                                                placeholder={pageData.topData.retryinterval.placeholder}
                                                fullWidth
                                                {...formik.getFieldProps("retry_count")} // Connect field to Formik
                                            />
                                        </Tooltip>
                                        <ErrorMessage
                                            name="retry_count"
                                            component="div"
                                            style={{ color: "red", marginTop: "5px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Tooltip
                                            title={pageData.topData.priority.tooltip}
                                            placement="top-start"
                                        >
                                            <FormControl sx={{ pt: 1, mb: 2, minWidth: 270 }}>
                                                <Select
                                                    options={pageData.topData.priority.options}
                                                    placeholder={pageData.topData.priority.placeholder}
                                                    isSearchable
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(
                                                            `priority`,
                                                            selectedOption.value || ""
                                                        )
                                                    }
                                                    menuPosition="fixed"
                                                    menuPlacement="auto"
                                                />
                                            </FormControl>
                                        </Tooltip>
                                        <ErrorMessage
                                            name="priority"
                                            component="div"
                                            style={{ color: "red", marginTop: "5px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Tooltip
                                            title={pageData.topData.drugTest.tooltip}
                                            placement="top-start"
                                        >
                                            <FormControl sx={{ pt: 1, mb: 2, minWidth: 200 }}>
                                                <Select
                                                    options={projectInfo.length > 0 ? projectInfo : []}
                                                    placeholder={pageData.topData.drugTest.placeholder}
                                                    isSearchable
                                                    onChange={(selectedOption) => {
                                                        return (
                                                            formik.setFieldValue(
                                                                "drugTest",
                                                                selectedOption.value || ""
                                                            ),
                                                            setOptionData(selectedOption)
                                                        );
                                                    }}
                                                    menuPosition="fixed"
                                                    menuPlacement="auto"
                                                />
                                            </FormControl>
                                        </Tooltip>
                                        <ErrorMessage
                                            name="drugTest"
                                            component="div"
                                            style={{ color: "red", marginTop: "5px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        {Object.keys(optionData).length > 0 ? (
                                            <Tooltip
                                                title={pageData.topData.buttons.editdrug.tooltip}
                                                placement="top-start"
                                            >
                                                <Button
                                                    sx={{ mt: 1, ml: 2 }}
                                                    variant={pageData.topData.buttons.editdrug.variant}
                                                    color={pageData.topData.buttons.editdrug.color}
                                                    size={pageData.topData.buttons.editdrug.size}
                                                    onClick={() => {
                                                        if (Object.keys(optionData).length > 0) {
                                                            const selectedProject = projectInfo.find(
                                                                (project) =>
                                                                    project.project_id === optionData.value
                                                            );
                                                            if (selectedProject) {
                                                                setSelectedProjectData(selectedProject);
                                                            }
                                                            setDisplayInfo(true);
                                                            setActionType("Edit");
                                                        }
                                                    }}
                                                >
                                                    <ModeEditOutlineIcon />
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={pageData.topData.buttons.adddrug.tooltip}
                                                placement="top-start"
                                            >
                                                <Button
                                                    sx={{ mt: 1, ml: 2 }}
                                                    variant={pageData.topData.buttons.adddrug.variant}
                                                    color={pageData.topData.buttons.adddrug.color}
                                                    size={pageData.topData.buttons.adddrug.size}
                                                    onClick={() => {
                                                        return setDisplayInfo(true), setActionType("Add");
                                                    }}
                                                >
                                                    <AddCircleOutlineIcon />
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip
                                            title={pageData.topData.buttons.posttoDialer.tooltip}
                                            placement="top-start"
                                        >
                                            <Button
                                                sx={{ m: 1 }}
                                                variant={pageData.topData.buttons.posttoDialer.variant}
                                                color={pageData.topData.buttons.posttoDialer.color}
                                                size={pageData.topData.buttons.posttoDialer.size}
                                                type="submit"
                                            >
                                                {pageData.topData.buttons.posttoDialer.label}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip
                                            title={pageData.topData.buttons.cancel.tooltip}
                                            placement="top-start"
                                        >
                                            <Button
                                                sx={{ m: 1 }}
                                                variant={pageData.topData.buttons.cancel.variant}
                                                color={pageData.topData.buttons.cancel.color}
                                                size={pageData.topData.buttons.cancel.size}
                                                onClick={() => {
                                                    return (
                                                        setDisplayInfo(false),
                                                        onCloseDialog(),
                                                        setActionType("")
                                                    );
                                                }}
                                            >
                                                {pageData.topData.buttons.cancel.label}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    {displayInfo === true && (
                        <>
                            <hr />
                            <DialogTitle sx={{ m: 0, p: 0 }} id="customized-dialog-title">
                                {actionType} Project
                            </DialogTitle>
                            <Formik
                                initialValues={
                                    actionType === "Add"
                                        ? pageData.bottomData.initial
                                        : selectedProjectData
                                }
                                onSubmit={onSubmitBottom}
                                validationSchema={validationBottomSchema}
                            >
                                {(formik) => (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            <Grid item xs={12} sm={6}>
                                                <Tooltip
                                                    title={pageData.bottomData.project_name.tooltip}
                                                    placement="top-start"
                                                >
                                                    <TextField
                                                        variant="standard"
                                                        label={pageData.bottomData.project_name.label}
                                                        name={pageData.bottomData.project_name.name}
                                                        type={pageData.bottomData.project_name.type}
                                                        placeholder={
                                                            pageData.bottomData.project_name.placeholder
                                                        }
                                                        value={
                                                            formik.values[
                                                            pageData.bottomData.project_name.name
                                                            ]
                                                        }
                                                        fullWidth
                                                        {...formik.getFieldProps("project_name")} // Connect field to Formik
                                                    />
                                                </Tooltip>
                                                <ErrorMessage
                                                    name="project_name"
                                                    component="div"
                                                    style={{ color: "red", marginTop: "5px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Tooltip
                                                    title={pageData.bottomData.project_sdate.tooltip}
                                                    placement="top-start"
                                                >
                                                    <TextField
                                                        variant="standard"
                                                        focused
                                                        label={pageData.bottomData.project_sdate.label}
                                                        name={pageData.bottomData.project_sdate.name}
                                                        type={pageData.bottomData.project_sdate.type}
                                                        value={
                                                            formik.values[
                                                                pageData.bottomData.project_sdate.name
                                                            ]
                                                                ? new Date(
                                                                    formik.values[
                                                                    pageData.bottomData.project_sdate.name
                                                                    ]
                                                                )
                                                                    .toISOString()
                                                                    .split("T")[0]
                                                                : ""
                                                        }
                                                        placeholder={
                                                            pageData.bottomData.project_sdate.placeholder
                                                        }
                                                        fullWidth
                                                        {...formik.getFieldProps("project_sdate")} // Connect field to Formik
                                                    />
                                                </Tooltip>
                                                <ErrorMessage
                                                    name="project_sdate"
                                                    component="div"
                                                    style={{ color: "red", marginTop: "5px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Tooltip
                                                    title={pageData.bottomData.project_edate.tooltip}
                                                    placement="top-start"
                                                >
                                                    <TextField
                                                        variant="standard"
                                                        focused
                                                        label={pageData.bottomData.project_edate.label}
                                                        name={pageData.bottomData.project_edate.name}
                                                        type={pageData.bottomData.project_edate.type}
                                                        value={
                                                            formik.values[
                                                                pageData.bottomData.project_edate.name
                                                            ]
                                                                ? new Date(
                                                                    formik.values[
                                                                    pageData.bottomData.project_edate.name
                                                                    ]
                                                                )
                                                                    .toISOString()
                                                                    .split("T")[0]
                                                                : ""
                                                        }
                                                        placeholder={
                                                            pageData.bottomData.project_edate.placeholder
                                                        }
                                                        fullWidth
                                                        {...formik.getFieldProps("project_edate")} // Connect field to Formik
                                                    />
                                                </Tooltip>
                                                <ErrorMessage
                                                    name="project_edate"
                                                    component="div"
                                                    style={{ color: "red", marginTop: "5px" }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip
                                                    title={pageData.bottomData.buttons.save.tooltip}
                                                    placement="top-start"
                                                >
                                                    <Button
                                                        sx={{ m: 1 }}
                                                        variant={pageData.bottomData.buttons.save.variant}
                                                        color={pageData.bottomData.buttons.save.color}
                                                        size={pageData.bottomData.buttons.save.size}
                                                        type="submit"
                                                    >
                                                        {pageData.bottomData.buttons.save.label}
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip
                                                    title={pageData.bottomData.buttons.cancel.tooltip}
                                                    placement="top-start"
                                                >
                                                    <Button
                                                        sx={{ m: 1 }}
                                                        variant={pageData.bottomData.buttons.cancel.variant}
                                                        color={pageData.bottomData.buttons.cancel.color}
                                                        size={pageData.bottomData.buttons.cancel.size}
                                                        onClick={() => {
                                                            return setDisplayInfo(false), setActionType("");
                                                        }}
                                                    >
                                                        {pageData.bottomData.buttons.cancel.label}
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DataAllocationPopup;
