import React from 'react';
import TabModel from './Multimedia/TabModel';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import UpdateInformation from './UpdateInformation';
import api from '../../utils/api';

const AgentAction = (props) => {
    const { userInfo } = useSelector((state) => state?.user?.value);
    const [pageData, setpageData] = useState("");
    const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
    
    async function pageInfo(keypointer) {
        api.get(`appuser/getcomponetbyid/${props?.data?.campinfo?.keypointer}/42`,
            {
                headers: {
                    Authorization: userInfo.data[0].UserToken
                }
            }).then(async (data) => {
                await setpageData(data.data.data[0].DATA)
            });
    }
    useEffect(async () => {
        await pageInfo();
    }, [])

     return (
        <>
            {
                Object.keys(pageData).length > 0 ? (
                    <div className="mt-2 card d-inline ml-1">
                        <button
                            type="button"
                            className="btn btn-primary-sm mb-3"
                            data-bs-toggle="modal"
                           
                            data-bs-target="#modalMultimedia"
                            title={pageData.button3}
                            data-toggle="tooltip">
                            {pageData.button3}
                        </button>
                        <button type="button"
                            className="btn btn-primary-sm mb-3"
                            data-bs-toggle="modal"
                            data-bs-backdrop="static"
                            data-bs-target="#modalUpdate"
                            title="Customer Information"
                            data-toggle="tooltip"
                            
                        >
                            Volunteer Information
                        </button>
                        <button type="button"  className="btn btn-primary-sm mb-3" data-bs-toggle="modal" data-bs-target="#Pdf script" title="Tool" data-toggle="tooltip">
                            <div>
                                <div className="dropdown">
                                    <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                       
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Tools
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {/*enter the url you want inside href atribute*/}
                                        <a className="dropdown-item" href="http://172.16.1.161/script/script.aspx"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Products One Pager
                                        </a>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Branch Locator
                                        </a>
                                        <a className="dropdown-item" href="#"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Happy Calculator
                                        </a>
                                        <a className="dropdown-item" href="#"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            FAQs
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </button>
                        <div className="modal fade"
                            id="modalUpdate"
                            tabindex="-1"
                            role="dialog"
                            data-bs-backdrop="static"
                            aria-labelledby="modalTitleNotify"
                            aria-hidden="false"
                        >
                            <div className="modal-dialog modal-dialog-centered ml-1" role="document">
                                <div className="modal-content " style={{ marginTop: "250px" }}>
                                    <div className="modal-header">
                                        <p className="modal-title" id="modalTitleNotify">
                                            Update Customer Information
                                        </p>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body" style={{ padding: " 0.6rem" }}>
                                        <UpdateInformation userInfo={props} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade"  data-bs-backdrop="static" id="modalMultimedia" tabindex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered ml-1" role="document">
                                <div className="modal-content" style={{ marginTop: "250px" }}>
                                    <div className="modal-header">
                                        <p className="modal-title" id="modalTitleNotify">{pageData.Multimedia?.header}</p>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <TabModel   userInfo={{...props.data}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ""}
        </>
    )
}
export default AgentAction;