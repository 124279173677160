import axios from "axios";

const api = axios.create({
  //baseURL:'http://localhost:5000/api/',
  baseURL: 'https://veedaapi.a10s.in/api/',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});


export default api;
