import { Form, Formik } from 'formik'
import React from 'react'
import { TextField } from '../../formValidation/TextField'
import { DataGrid } from "@mui/x-data-grid";
import DaynmicApicall from '../../../utils/function';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import api from '../../../utils/api';
import toast from 'react-hot-toast';


export default function CustomerSearchPage(props) {
    const { userInfo } = useSelector((state) => state?.user?.value);
    const [tableData, setTableData] = useState("")
    let DialerCrmData = JSON.parse(JSON.parse(localStorage.currentcall).crm_data)
    const handleChildToProp = (event, cellValues) => {
        let searchdata = props.dialerdata
        searchdata.event_data.crm_data.custid = cellValues.row.cuid
        props?.searchTOCrm(searchdata);
    };
    const columns = [
        { field: "cuid", headerName: "Customer Id", width: 100 },
        { field: "policy_no", headerName: "Policy No", width: 200 },
        { field: "productname", headerName: "Product Name", width: 200 },
        { field: "policy_owner", headerName: "Customer Name", width: 200 },
        { field: "email_id", headerName: "Email", width: 200 },
        { field: "contact_no", headerName: "Phone Number", width: 180 },
        { field: "agent_broker_name", headerName: "Agent Name", width: 150 },
        {
            field: "Action",
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <Link
                        to="/dashboard"
                        variant="contained"
                        color="info"
                        onClick={async (event) => {
                            // handleChildToProp(event, cellValues)
                            let searchdata = props.dialerdata
                            searchdata.event_data.crm_data.custid = cellValues.row.cuid
                            searchdata.event_data.call_status = "searchpage"
                            console.log("searchdata: ", searchdata)
                            props?.searchTOCrm(searchdata);
                        }}
                    >
                        Go To Home Page
                    </Link>
                );
            }
        },
    ];
    const initial = {
        "cuid": "",
        "campid": "",
        "dob": "",
        "policyno": "",
        "custid": "111V0654837",
        "campid": "111",
        "agentid": "",
        "phone": "",
        "dialerrespose": "incoming",
        "clientid": "",
        "callmode": "",
        "sessionid": "pxd2pwhdr",
        "notifyurl": "http://172.16.1.207:2008/dashboard",
        "call_status": "answered",
        "call_start": 1672230936401,
        "call_stop": "",
        "event_data": {
            "caller_number": "+918267809214",
            "caller_name": "+918267809214",
            "call_session_id": "pxd2pwhdr",
            "call_uuid": "sip:+918267809214@dialer01;user=phone",
            "call_direction": "incoming",
            "call_status": "answered",
            "call_start": 1672230936401,
            "call_stop": "",
            "crm_data": {
                "custid": "111V0654837",
                "campid": "41",
                "agentid": "",
                "phone": "8267809214",
                "dialerresponse": "",
                "clientid": "",
                "callmode": "",
                "sessionid": "",
                "pass_on_time": ""
            },
            "agent_token": "2046"
        }
    }

    const onSubmit = async (values, { resetForm }) => {
        let cuid = values.cuid ? values.cuid : 'NA'
        let phone = values.phone ? values.phone : 'NA'
        let volunteer = values.volunteer ? values.volunteer : 'NA'
        let volunteername =
            // :custid/:phnno/:volunteerid/:volunteername
            await DaynmicApicall(`mcrmdlr/getcustomersearch/${cuid}/${phone}/${volunteer}/NA`, '', 'get', userInfo.data[0].UserToken).then((res) => {
                setTableData(res);
            })
    }
    const sendData = async (values) => {
        // let values.cuid = custid;
        await DaynmicApicall(`mcrmdlr/getuatdialerdata/`, '', 'get', userInfo.data[0].UserToken).then((res) => {
            // setTableData(res);
        })
    }

    const pageData =
    {
        customerid: {
            "title": "Please Enter Customer Id",
            "label": "Customer Id",
            "placeholder": "Enter Coustomer Id",
            "type": "text",
            "name": "cuid"
        },
        phonenum: {
            "title": "Please Enter Customer Phone Number",
            "label": "Phone Number",
            "placeholder": "Enter Phone Number",
            "type": "text",
            "name": "phone"
        },
        dodate: {
            "title": "Please Enter Customer DOB",
            "label": "Date of Birth",
            "placeholder": "Enter Date of birth",
            "type": "date",
            "name": "dob"
        },
        policynumber: {
            "title": "Please enter volunteer number",
            "label": "Volunteer Number",
            "placeholder": "Enter Policy Number",
            "type": "text",
            "name": "volunteer"
        },
        agentidnum: {
            "title": "Please Enter Agent Id",
            "label": "Agent Id",
            "placeholder": "Enter Agent Id",
            "type": "text",
            "name": "agentid"
        },

        submitbtn: {
            "label": "Submit"
        },

        sendbtn: {
            "label": "Send"
        }
    }

    async function callerhangup() {
        const parameter = {
            // "inboundcallerchannelid": DialerCrmData.inboundcallerchannelid
            channelid: DialerCrmData.inboundcallerchannelid,
            crmuuid: DialerCrmData.dialeruuid
        }
        // const Info = await DaynmicApicall(`dialer/callerhangup`, parameter, 'post', userInfo.data[0].UserToken)
        const Info = api.post("dialer/callerhangup", parameter, { headers: { Authorization: userInfo.data[0].UserToken } });
        toast
            .promise(Info, {
                // loading: "Dissconniting  member.",
                success: (Info) => {
                    return Info.data.message;
                },
                error: (err) => {
                    return (
                        err?.response?.data?.errors ??
                        "Please contact to dialer admin & Save the call" ??
                        err?.message ??
                        "OOPs something went wrong."
                    );
                },
            })
            .then(async(data) => {
                const hangupdata = {
                    "crmuuid": DialerCrmData.dialeruuid,
                    "external_hangup": "1",
                    "external_status": "Dialer Hangup",
                    "agentid": `${userInfo.data[0].AgentDidNo}`
                }
                await DaynmicApicall(`dialer/dialerhangup`, hangupdata, 'post', userInfo.data[0].UserToken)
                props.aftersave('');
            }).catch((error) => {
                props.aftersave('');
            })

    }

    return (
        <div>
            <div className='card card-body'>
                <Formik
                    initialValues={initial}
                    //  validationSchema={validate}
                    onSubmit={onSubmit}
                >
                    {(formik) => (

                        <Form>
                            <div className="row mt-2">
                                <TextField
                                    data-toggle="tooltip"
                                    title={pageData.customerid.title}
                                    label={pageData.customerid.label}
                                    placeholder={pageData.customerid.placeholder}
                                    type={pageData.customerid.type}
                                    name={pageData.customerid.name}
                                />
                                <TextField
                                    data-toggle="tooltip"
                                    title={pageData.phonenum.title}
                                    label={pageData.phonenum.label}
                                    placeholder={pageData.phonenum.placeholder}
                                    type={pageData.phonenum.type}
                                    name={pageData.phonenum.name}
                                />
                                {/* <TextField
                                    data-toggle="tooltip"
                                    title={pageData.dodate.title}
                                    label={pageData.dodate.label}
                                    placeholder={pageData.dodate.placeholder}
                                    type={pageData.dodate.type}
                                    name={pageData.dodate.name}
                                /> */}
                                <TextField
                                    data-toggle="tooltip"
                                    title={pageData.policynumber.title}
                                    label={pageData.policynumber.label}
                                    placeholder={pageData.policynumber.placeholder}
                                    type={pageData.policynumber.type}
                                    name={pageData.policynumber.name}
                                />
                                {/* <TextField
                                    data-toggle="tooltip"
                                    title={pageData.agentidnum.title}
                                    label={pageData.agentidnum.label}
                                    placeholder={pageData.agentidnum.placeholder}
                                    type={pageData.agentidnum.type}
                                    name={pageData.agentidnum.name}
                                /> */}
                                <div className='justify-content-end'>
                                    <button
                                        type="submit"
                                        className="btn btn-primary p-2 m-2"

                                    >{pageData.submitbtn.label}</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div>
                    <div className='justify-content-end'>
                        <button className="btn btn-primary p-2 m-2"
                            onClick={callerhangup}
                        >
                            Call Hangup
                        </button>
                    </div>
                </div>
            </div>

            <div className=' card mt-3'>
                <div className="card card-body border-light shadow">
                    <div className="table-settings mb-4">
                        <div className="my-2">
                            <DataGrid
                                getRowId={(r) => r.cuid}
                                rows={tableData}
                                columns={columns}
                                autoHeight={true}
                                className="bg-white"
                            />
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary btn-sm m-2"
                                    onClick={sendData}
                                >
                                    {pageData.sendbtn.label}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
