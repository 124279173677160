import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import "./style.css";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import './style.css';
import toast from "react-hot-toast";
import DaynmicApicall, { ManageEventLog } from "../../utils/function";
import socket from "../../utils/socket";
import TtyIcon from '@mui/icons-material/Tty';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import { setCrmWebphone } from "../../redux/dialerStates";
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import CircleIcon from '@mui/icons-material/Circle';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import { colors } from "@mui/material";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CallEndIcon from '@mui/icons-material/CallEnd';
import api from "../../utils/api";

export default function Dialer() {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { UserToken, userid, usergroup, dlrAgentId } = userInfo.data[0];
  const { campdetail } = useSelector((state) => state.campaign);
  const dispatch = useDispatch()
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [lucnhbreak, setLucnhbreak] = useState(false)
  const [sortbreak, setSortbreak] = useState(false)
  const [callholdUnhold, setCallholdUnhold] = useState(false);
  const [getQueue, setQueue] = useState([]);
  const [callmuteUnmute, setcallmuteUnmute] = useState(false);
  const [getConferenceInfo, SetConferenceInfo] = useState("");
  const [wepphoneconnect, setWebphoneconnect] = useState("");
  const [getRegister, setRegister] = useState("Not Registered");
  const [datacall, setcalltransfer] = useState("");
  const [dialeCall, setdialeCall] = useState("");
  const [reportinglist, setReportinglist] = useState("");
  const [getfollowupdata, setfollowupdata] = useState([]);
  const TIME = new Date().toLocaleTimeString('en-gb', { hour12: false }).split(":")
  const DialerCrmData = (localStorage['currentcall'] && userInfo.data[0].usergroup === 'AGENT') ? JSON.parse(JSON.parse(localStorage.currentcall).crm_data) : ''

  const f_getConnectedCamp = async () => {
    const camp = await DaynmicApicall(
      `appuser/getcampbycampid/ALL/${userid}`,
      "",
      "get",
      UserToken
    );
    const queue = camp?.map((data) => {
      return {
        queue_name: data.campname, // queue
        queue_number: data.queue_name,
      };
    });
    setQueue(queue);
  };

  const getfollowup = async () => {
    const Info = await DaynmicApicall(`mcrmdlr/gettodayfollowup/${userInfo.data[0].userid}`, '', 'get', userInfo.data[0].UserToken)
    setfollowupdata(Info)
  }

  const getcalltransfer = () => {
    return setcalltransfer(!datacall)
  }
  useEffect(() => {
    getfollowup()
    f_getConnectedCamp()
    getreportingmemberList()
  }, [])

  useEffect(() => {
    if (getRegister === "Not Registered") {
      dispatch(setCrmWebphone({
        queueStatus: false,
      }))
    }
    var Connection = socket.connect('');

    window.addEventListener("offline", (event) => {
      setRegister("Not Registered");
      dispatch(setCrmWebphone({
        queueStatus: false,
      }))
    });

    if (Connection.connected === false) {
      setRegister("Not Registered");
      dispatch(setCrmWebphone({
        queueStatus: false,
      }))
    }

    socket.on("respfromdialer", async (data) => {
      console.log("respfromdialer: ", data)
      switch (data.action) {
        case "message_info":
          toast.success(data.event_data.message_text)
          switch (data.event_data.message_text) {
            case "Inbound Caller Hangup":
              dispatch(setCrmWebphone({
                dialerHangupStatus: false,
              }))
              break;
          }
          // }
          break
        case "get_call_transfer":
          const l1 = document.getElementById("calltransfer");
          l1.click();
          break;
        case "register_state":
          setRegister(data.event_data.register_state.toUpperCase());
          dispatch(setCrmWebphone({
            queueStatus: data.event_data.register_state,
          }))
          if (data.event_data.register_state === "registered" && userInfo.data[0].usergroup !== "AGENT") {
            setLucnhbreak(true);
            let pause = {
              agent_token: userInfo.data[0]?.dlrAgentId,
              action: "dial_call",
              event_data: {
                dial_number: "*42900003"// Number which needs to dial breack code 
              }
            }
            socket.emit("crmdialerdata", pause);
          }
          break
        case "call_state":
          setWebphoneconnect(data.event_data.call_status);
          dispatch(setCrmWebphone({
            dialerHangupStatus: data.event_data.call_status,
          }))
          // await DaynmicApicall(`/mcrmdlr/webphoneeventslog`, data.event_data, 'post', userInfo.data[0].UserToken).then((data) => {
          // })
          break;
        case "toggle_mute":
          console.log("toggle_mute", data)
          break;
        case "attx_xfer":
          break;
        case "toggle_hold":
          console.log("toggle_hold", data)
          break;
        case "send_dtmf":
          break;
        case "conference_info":
          SetConferenceInfo(data)
        default:
        // cccccccc
      }
    });

    socket.on("crmdialerdata", async (data) => {
      console.log("crmdialerdata", data)
    })
  }, []);

  async function callholdAndUnhold(event) {
    let holdUnhold = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "toggle_hold",
      event_data: {
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
        hold_action: callholdUnhold === true ? "unhold" : "hold", // value "hold" or "unhold" will be based on control action
      },
    };
    socket.emit('crmdialerdata', holdUnhold)
  }
  async function callMuteAndUnmute() {
    setcallmuteUnmute(callmuteUnmute === false ? true : false);
    let muteunmute = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "toggle_mute",
      event_data: {
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
        mute_action: callmuteUnmute === true ? "unmute" : "mute", // value "mute" or "unmute" will be based on control action
      },
    };
    socket.emit("crmdialerdata", muteunmute);
  }
  async function blindcalltranfer(number) {
    const callinfo = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "btx_xfer",
      event_data: {
        xfer_to: number, // Transfer To Number
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
      }
    }
    socket.emit("crmdialerdata", callinfo);
  }

  async function auditTrails(taskName, errMessage, errRemarks) {
    const parameter = {
      "sNo": '404',
      "taskName": taskName,
      "errMessage": errMessage,
      "errRemarks": errRemarks
    }
    const Info = await DaynmicApicall(`err/setError/`, parameter, 'post', userInfo.data[0].UserToken)
  }

  async function callend() {
    if (userInfo.data[0].usergroup !== 'AGENT') {
      let endcall = {
        agent_token: userInfo.data[0]?.dlrAgentId,
        action: "end_call",
        event_data: {
          call_session_id: JSON.parse(localStorage.currentcall).call_session_id // Call disconnect
        }
      }
      socket.emit("crmdialerdata", endcall)
      localStorage.removeItem("currentcall")
    } else {
      const parameter = {
        // "inboundcallerchannelid": DialerCrmData.inboundcallerchannelid
        channelid: DialerCrmData.inboundcallerchannelid,
        crmuuid: DialerCrmData.dialeruuid
      }
      // const Info = await DaynmicApicall(`dialer/callerhangup`, parameter, 'post', userInfo.data[0].UserToken)
      const Info = api.post("dialer/callerhangup", parameter, { headers: { Authorization: userInfo.data[0].UserToken } });
      toast
        .promise(Info, {
          // loading: "Dissconniting  member.",
          success: (Info) => {
            return Info.data.message;
          },
          error: (err) => {
            return (
              err?.response?.data?.errors ??
              "Please contact to dialer admin & Save the call" ??
              err?.message ??
              "OOPs something went wrong."
            );
          },
        })
        .then((data) => {
          let endcall = {
            agent_token: userInfo.data[0]?.dlrAgentId,
            action: "end_call",
            event_data: {
              call_session_id: getConferenceInfo.event_data.conf_id // Call disconnect
            }
          }
          socket.emit("crmdialerdata", endcall)
          let actionlog = {
            "Rowed": "NEW",
            "custid": crmsaveandexit.cust_id,
            "campid": crmsaveandexit.campid,
            "eventname": `OnClick`,
            "actionname": "",
            "createdby": userInfo.data[0].userid
          }
          ManageEventLog(actionlog)
          dispatch(setCrmWebphone({
            dialerCallHangup: true,
          }))
          // localStorage.removeItem("currentcall")
          setWebphoneconnect("")
        }).catch((error) => {
          dispatch(setCrmWebphone({
            dialerCallHangup: true,
          }))
          auditTrails("callerhangup", error.response?.data?.message, error.response.request.responseURL)
        })
    }

  }
  async function dial_call_tranfer(number) {
    callholdAndUnhold()
    const dial_call = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "dial_call",
      event_data: {
        dial_number: `${number}` // Number which needs to dial
      }
    }
    socket.emit("crmdialerdata", dial_call);
    socket.on("respfromdialer", async (data) => {
      if (data.action === "call_state" && data.event_data.caller_number == number)
        setdialeCall(data.event_data)
      dispatch(setCrmWebphone({
        setdialeCall: data.event_data,
      }))
    })
  }
  async function attandcalltransfer() {
    const callinfo = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "attx_xfer",
      event_data: {
        xfer_call_session_id: dialeCall.call_session_id, // Transfer To Number
        call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
      }
    }
    socket.emit("crmdialerdata", callinfo);

  }
  async function call_conference() {
    const callinfo = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      action: "create_conference",
      event_data: {
        call_session_id: dialeCall.call_session_id, // Transfer To Number
        sup_call_session_id: crmsaveandexit?.dialer_session_id, // CALL UNIQUE SESSION ID OR UUID
      }
    }
    socket.emit("crmdialerdata", callinfo);

  }
  // async function fn_lunchbreak() {
  //   console.log("campdetail::: ", campdetail)
  //   setLucnhbreak(!lucnhbreak);
  //   // const queueNames = campdetail.map(item => item.queue_name);
  //   await api.post("/ami/agent/pause", {
  //     "actionid": "crmuuid",
  //     "queues": ["900003"],
  //     "interface": userInfo.data[0].dlrAgentId.toString(),
  //     "paused": lucnhbreak
  //   }, userInfo).catch((error) => {
  //     console.log("Error from ami/agent/pause::: ", error)
  //   })
  // }
  // async function fn_sortbreak() {
  //   setSortbreak(!sortbreak);
  //   let pause = {
  //     agent_token: userInfo.data[0]?.dlrAgentId,
  //     action: "dial_call",
  //     event_data: {
  //       dial_number: sortbreak === false ? "*44900003" : "*45900003" // Number which needs to dial breack code 
  //     }
  //   }
  //   toast.success(sortbreak === false ? "Sort break started" : "Sort break Ended");
  //   socket.emit("crmdialerdata", pause);
  // }

  async function fn_lunchbreak() {
    setLucnhbreak(!lucnhbreak);
    getQueue.map((data) => {
      let pause = {
        agent_token: dlrAgentId,
        action: "dial_call",
        event_data: {
          dial_number:
            lucnhbreak === false
              ? `*42${data.queue_number}`
              : `*43${data.queue_number}`, // Number which needs to dial breack code
        },
      };
      socket.emit("crmdialerdata", pause);
    });
  }
  async function fn_sortbreak() {
    setSortbreak(!sortbreak);
    getQueue.map((data) => {
      let pause = {
        agent_token: dlrAgentId,
        action: "dial_call",
        event_data: {
          dial_number:
            sortbreak === false
              ? `*44${data.queue_number}`
              : `*45${data.queue_number}`, // Number which needs to dial breack code
        },
      };
      socket.emit("crmdialerdata", pause);
    });
    toast.success(
      sortbreak === false ? "Sort break started" : "Sort break Ended"
    );
  }
  async function getreportingmemberList() {
    await DaynmicApicall(`appuser/getmasterdata/vw_reporting_ids/*/1=1/user_id/`, '', 'get', userInfo.data[0].UserToken)
      .then(async (res) => {
        setReportinglist(res);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.data.message ??
          error.message ??
          "OOPs, Something went wrong."
        );
      });
  }
  let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#']
  return (
    <>
      <div className="container-fulid">
        <div className="bg-dark text-light rounded m-0 p-0" >
          <p
            style={{ fontSize: "13px" }}
            className="text-center ">
            <SignalCellularAltIcon
              className={getRegister.includes('REGISTERED') ? "text-success" : "text-danger"}
              style={{ height: "17px" }} />
            {getRegister} ({wepphoneconnect})
          </p>
        </div>
        <div className="container">
          {/* Iframe */}
          <div className="">
            <iframe
              hidden
              title="webphone"
              // src={`https://sipphone.store/ats-webphone/dist1.1/?${userInfo.data[0].UserToken.split(" ")[1]}`}
              // https://sipphone.store/ats-webphone/dist-room/?1009 ============ for room socket.io room
              // src={`https://sipphone.store/ats-webphone/dist1.1/?${userInfo.data[0].dlrAgentId}`}
              // src={`https://sipphone.store/ats-webphone/dist-room/?${userInfo.data[0].dlrAgentId}`}
              src={`https://wphone.a10s.in/?${userInfo.data[0].dlrAgentId}`}
              allow="microphone"
            ></iframe>
          </div>
          {/* WebPhone */}
          <div className="">
            <div className="">
              <div className="card mt-4 border-2 border-secondary">
                <div className="row justify-content-center">
                  {crmsaveandexit.dialer_session_id && (
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0" >
                      <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                        <button
                          className="btn"
                          data-toggle="tooltip"
                          title="Re-Dail Call"
                        >
                          <TtyIcon />
                        </button>
                      </div>
                    </div>
                  )}
                  <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0" >
                    <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                      <button
                        disabled={lucnhbreak === true ? true : false}
                        className="btn"
                        type="button"
                        data-toggle="tooltip"
                        title="Short Break"
                        onClick={e => {
                          fn_sortbreak()
                        }}
                      >
                        {sortbreak === false ?
                          (<PauseCircleIcon data-toggle="tooltip" title="Short break start" />)
                          : (<EmojiFoodBeverageIcon data-toggle="tooltip" title="Short break end" />)}
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0" >
                    {/* <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary"> */}
                    <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                      <button
                        disabled={sortbreak === true ? true : false}
                        className="btn"
                        type="button"
                        data-toggle="tooltip"
                        title="Lunch Break"
                        onClick={e => {
                          fn_lunchbreak()
                        }}
                      >
                        {lucnhbreak === false ?
                          (<PhonePausedIcon data-toggle="tooltip" title="Call Pause" />)
                          : (<FastfoodIcon data-toggle="tooltip" title="Lunch Break" />)}
                      </button>
                    </div>
                  </div>

                  {crmsaveandexit.dialer_session_id && (
                    <>
                      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0" >
                        {/* <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary"> */}
                        <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                          <button
                            id="dialpad"
                            title={`${callholdUnhold === true ? "Call Unhold" : "Call Hold"}`}

                            onClick={(e) => {
                              setCallholdUnhold(callholdUnhold === false ? true : false);
                              callholdAndUnhold();
                            }}
                            className={
                              `${callholdUnhold === true ?
                                "bg-success" : "bg-white"
                              } btn rounded-5`}
                          >
                            {callholdUnhold ? < PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0" >
                        {/* <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary"> */}
                        <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                          <button
                            id="dialpad"
                            className={`${callmuteUnmute === true ? "bg-success" : "bg-white"
                              } btn rounded-5`}
                            data-toggle="tooltip"
                            title={`${callmuteUnmute === true ? "Unmute" : "Mute"}`}
                            onClick={callMuteAndUnmute}
                          >
                            {/* <img
                              src="Images/microphone.png"
                              alt="mute"
                              style={{ height: "25px" }}
                            /> */}
                            {callmuteUnmute ? <MicOffIcon /> : <MicIcon />}
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0" data-toggle="tooltip"
                        title="attended call transfer">
                        {/* <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary"> */}
                        <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                          <div class="">
                            <a class="btn " data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                              <AddIcCallIcon />
                            </a>

                          </div>
                          <div class="collapse" id="collapseExample">
                            <div class="card" style={{ height: "100px", width: "200px", marginLeft: "-150px" }}>
                              <div className="">
                                <div className="mt-3 dropdown">
                                  <button className="btn btn-sm btn-danger  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">calling list</button>
                                  <ul class="dropdown-menu mt-2" style={{ width: "150px", marginLeft: "-25px" }}>
                                    {
                                      reportinglist && reportinglist.map((data) => (
                                        <button class="dropdown-item" onClick={e => {
                                          dial_call_tranfer(data.dlr_agent_id)
                                          getcalltransfer("112")
                                        }}>{data.username}</button>
                                      ))
                                    }
                                  </ul>
                                </div>
                                {
                                  datacall && (
                                    <div className="" >
                                      <button className="btn btn-sm btn-success mt-3"
                                        style={{ width: "120px", height: "30px", fontSize: "10px" }} onClick={e => {
                                          attandcalltransfer()
                                        }}
                                      >
                                        Final call conference
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0" data-toggle="tooltip"
                        title="Conference call">
                        {/* <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary"> */}
                        <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                          <div class="">
                            <a class="btn " data-toggle="collapse" href="#conference" role="button" aria-expanded="false" aria-controls="conference">
                              <CallMergeIcon />
                            </a>

                          </div>
                          <div class="collapse" id="conference">
                            <div class="card" style={{ height: "100px", width: "200px", marginLeft: "-75px" }}>
                              <div className="">
                                <div className="mt-3 dropdown">
                                  <button className="btn btn-sm btn-danger  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">confrence list</button>
                                  <ul class="dropdown-menu mt-2" style={{ width: "auto", marginLeft: "-25px" }}>
                                    {
                                      reportinglist && reportinglist.map((data) => (
                                        <button class="dropdown-item" onClick={e => {
                                          dial_call_tranfer(data.dlr_agent_id)
                                          getcalltransfer("112")
                                        }}>{data.username}</button>
                                      ))
                                    }
                                  </ul>
                                </div>
                                {
                                  datacall && (
                                    <div className="" >
                                      <button className="btn btn-sm btn-success mt-3"
                                        style={{ width: "120px", height: "30px", fontSize: "10px" }} onClick={e => {
                                          call_conference()
                                        }}
                                      >
                                        final call transfer
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="col-2 ml-3 " data-toggle="tooltip"
                    title="Blind call transfer">
                    <div className="dropdown">
                      <button class="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <CallMergeIcon />
                      </button>
                      <ul class="dropdown-menu ">
                        {
                          reportinglist && reportinglist.map((data) => (
                            <button class="dropdown-item" onClick={e => {
                              blindcalltranfer(data.dlr_agent_id)
                            }}>{data.username}</button>
                          ))
                        }
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row ml-2 my-2">
              {
                userInfo.data[0].usergroup !== 'AGENT' && (
                  <div className="row my-3">
                    {arr && arr.map((data) => (
                      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0" >
                        <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary">
                          <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                            <span class="">{data}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              }
            </div>
            <div className="">
              {(crmsaveandexit.dialer_session_id || (userInfo.data[0].usergroup !== 'AGENT' && localStorage['currentcall'])) && (
                <button
                  className="btn btn-xl border-3 border-secondary mt-1 ml-2"
                  style={{ height: "40px", width: "205px", fontSize: "15px", fontWeight: "50px", background: "#dd0e0e", color: '#fff' }}
                  type="button"
                  data-toggle="tooltip"
                  title="Hangup call"
                  onClick={e => { callend() }}>
                  <CallEndIcon />
                </button>
              )}
            </div>
          </div>
        </div>
        {userInfo.data[0].usergroup === 'AGENT' && <div className="row">
          <>
            <div className="my-2">
              <div className="mt-2">
                <span className="font-weight-bold m-1">My Followup Calls(ATM)</span> <hr className="m-0" />
              </div>
              <div className="mb-1">
                <Table className="table-borderless ">
                  <tr className="bg-dark text-light">
                    <td className="font-weight-bold mx-0 px-1" style={{ fontSize: "10px" }}>Status</td>
                    <td className="font-weight-bold mx-0 px-2" style={{ fontSize: "10px" }}>Name</td>
                    <td className="font-weight-bold mx-0 px-2" style={{ fontSize: "10px" }}>Time</td>
                    <td className="font-weight-bold mx-0 px-2" style={{ fontSize: "10px" }}>Phone No</td>
                  </tr>
                  {getfollowupdata && getfollowupdata.map((data) => (
                    <tr >
                      <td className="px-0 px-1"> <span > <CircleIcon style={{
                        height: "10px", width: "10px",
                        color: data.revertto_time < (TIME[0] + TIME[1]) ? "green" : "orange"

                      }} /></span> </td>
                      <td className="text-bold px-2 " style={{ fontSize: "10px" }}>{data.cust_name}</td>
                      <td className="text-bold px-2 " style={{ fontSize: "10px" }}>{data.revertto_time}</td>
                      <td className="text-bold px-2 " style={{ fontSize: "10px" }}>xxxxxx{data.phone_no}</td>
                    </tr>
                  ))}

                </Table>

              </div>
            </div>
            {getfollowupdata.length === 0 && <div className="text-center">
              <span className='text-center' style={{ fontSize: "20px" }}  >No Data found</span>
            </div>}
          </>
        </div>
        }

        {/* <div className="row ml-2 my-2">
          {
            userInfo.data[0].usergroup !== 'AGENT' && (
              <div className="row my-3">
                {arr && arr.map((data) => (
                  <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 m-0" >
                    <div class="card mb-2 border-right-4 secondary m-0 border-2 border-secondary">
                      <div class="text-center" style={{ color: "#262B40", fontWeight: "bold", fontSize: "20px" }}>
                        <span class="">{data}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        </div> */}



      </div>
    </>
  );
}








// hold/unhold
// mute/unmute
// call transfer
// queue Logout
// queue Login
// pause/unpause
// call confonce
// listin barge wishper