import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Toggle } from "../../components/formValidation/Toggle";
import { TextField } from "../../components/formValidation/TextField";
import { MultiSelect } from "../../components/formValidation/MultiSelect";
import * as Yup from "yup";
import { setParems } from "../../redux/Campaign";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import DaynmicApicall from "../../utils/function";
import { Link } from "react-router-dom";

const MasterDailerQueue = (props) => {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [playlist, setPlaylist] = useState([]);
  const [joinempty, setjoinEmpty] = useState([]);
  const [strategy, setstrategy] = useState([]);
  const [leavewhenempty, setLeavempty] = useState([]);
  const authToken = userInfo.token;
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const Header = { headers: { Authorization: userInfo.data[0].UserToken } };
  const [getQueue, setqueue] = useState("");
  const [pageData, setpageData] = useState("");
  const getqueueList = async () => {
    let Info = await DaynmicApicall(
      `/dialer/getdialerqueue`,
      "",
      "get",
      userInfo.data[0].UserToken
    );
    setqueue(Info);
  };

  async function AudioPlayList() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/AUD_FILES`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paravalue,
            label: item.paracode + ` (${item.paravalue} ) `,
          };
        });
        setPlaylist(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  async function getStrategy() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/DIALER_QUEUE_STRATEGY`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setstrategy(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  async function pageInfo() {
    await DaynmicApicall(
      `/appuser/getcomponetbyid/${props.data.activeMenu.keypointer}/${props.data.activeMenu.subcompid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    ).then((data) => {
      setpageData(data[0].DATA);
    });
  }

  async function joinEmptydata() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/DIALER_QUEUE_JOINEMPTY`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setjoinEmpty(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  async function LeaveEmpty() {
    await DaynmicApicall(
      `prm/getParamdata/ALL/DIALER_QUEUE-LEAVEEMPTY`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then(async (res) => {
        let bData = [];
        bData = await res.map((item) => {
          return {
            value: item.paracode,
            label: item.paravalue,
          };
        });
        setLeavempty(bData);
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, something went wrong."
        );
      });
  }

  useEffect(async () => {
    await AudioPlayList();
    await getStrategy();
    await joinEmptydata();
    await LeaveEmpty();
    await getqueueList();
    await pageInfo();
  }, []);

  let action = props?.data?.paremsData.action === "UPDATE";
  let initial = {
    name: action ? props?.data?.paremsData.comp1.musiconhold : "",
    queue_id: action ? props?.data?.paremsData.comp1.name : "",
    // entry: action ? props?.data?.paremsData.comp1.entry : "",
    entry: action
      ? playlist?.find(
          (v) => v?.value === props?.data?.paremsData?.comp1?.entry
        )
      : "",
    queue_name: action ? props?.data?.paremsData.comp1.queue_name : "",
    musiconhold: action ? props?.data?.paremsData.comp1.musiconhold : "",
    timeout: action ? props?.data?.paremsData.comp1.timeout : "",
    ringinuse: action
      ? props?.data?.paremsData.comp1.ringinuse === "yes"
        ? true
        : false
      : "",
    autofill: action
      ? props?.data?.paremsData.comp1.autofill === "yes"
        ? true
        : false
      : "",
    maxlen: action ? props?.data?.paremsData.comp1.maxlen : "",
    strategy: action
      ? strategy?.find(
          (v) => v?.value === props?.data?.paremsData?.comp1?.strategy
        )
      : "",
    joinempty: action
      ? joinempty?.find(
          (v) => v?.value === props?.data?.paremsData?.comp1?.joinempty
        )
      : "",
    leavewhenempty: action
      ? leavewhenempty?.find(
          (v) => v?.value === props?.data?.paremsData?.comp1?.leavewhenempty
        )
      : "",
    // leavewhenempty:  action ? props?.data?.paremsData.comp1.leavewhenempty: "",
    reportholdtime: action
      ? props?.data?.paremsData.comp1.reportholdtime === "yes"
        ? true
        : false
      : "",
    announcementstatus: action
      ? props?.data?.paremsData.comp1.announcementstatus === "yes"
        ? true
        : false
      : "",
    annoucement_path: action
      ? props?.data?.paremsData.comp1.annoucement_path
      : "",
    action_name: action ? "UPDATE" : "INSERT",
  };
  // console.log(initial,'initial')
  const validate = Yup.object({
    name: Yup.string().required("name  is required"),
  });
  const onSubmit = async (values, { resetForm }) => {
    let entry = values?.entry?.map((v) => v.value);
    values.entry = entry;
    values.joinempty = values.joinempty.label;
    values.leavewhenempty = values.leavewhenempty.label;
    values.strategy = values.strategy.label;
    values.musiconhold = values.name;
    values.reportholdtime = values.reportholdtime === true ? "yes" : "no";
    values.autofill = values.autofill === true ? "yes" : "no";
    values.announcementstatus =
      values.announcementstatus === true ? "yes" : "no";
    values.ringinuse = values.ringinuse === true ? "yes" : "no";
    if (props.data.paremsData.action === "INSERT") {
      const Info = await DaynmicApicall(
        `/dialer/managedialerdata`,
        values,
        "post",
        userInfo.data[0].UserToken
      );
      toast.success(Info.message);
      dispatch(setParems({ data: "" }));
    } else {
      const Info = await DaynmicApicall(
        `/dialer/updateQdata`,
        values,
        "post",
        userInfo.data[0].UserToken
      );
      toast.success(Info.message);
      dispatch(setParems({ data: "" }));
    }
  };

  console.log("strategy: ", strategy);
  return (
    <>
      {pageData && (
        <>
          <div className="container-fluid py-3 bg ">
            <div className="row">
              <div className="card col-xl-12 bg-dark">
                <div className="row row-cols-md-2 m-2 p-1 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                  <span className="text-left text-light ">
                    {pageData.title}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <div className="card card-body  border-light shadow ml-2">
                <Form>
                  <div className="row">
                    {pageData.label}
                    <div
                      style={{
                        borderBottom: "1px solid #d5d9de ",
                        height: "5px",
                      }}
                    ></div>
                    <TextField
                      title={pageData.queuename.title}
                      data-toggle="tooltip"
                      label={pageData.queuename.label}
                      placeholder={pageData.queuename.placeholder}
                      type={pageData.queuename.type}
                      name={pageData.queuename.name}
                      disabled={props?.data?.paremsData.action === "UPDATE"}
                    />
                    <TextField
                      title={pageData.ringinuse.title}
                      data-toggle="tooltip"
                      label={pageData.ringinuse.label}
                      placeholder={pageData.ringinuse.placeholder}
                      type={pageData.ringinuse.title}
                      name={pageData.ringinuse.name}
                    />
                    <TextField
                      title={pageData.maxincomin.title}
                      data-toggle="tooltip"
                      label={pageData.maxincomin.label}
                      placeholder={pageData.maxincomin.label}
                      type={pageData.maxincomin.label}
                      name={pageData.maxincomin.name}
                    />
                    <MultiSelect
                      title={pageData.strategy.title}
                      data-toggle="tooltip"
                      label={pageData.strategy.label}
                      name={pageData.strategy.name}
                      isMulti={false}
                      options={strategy}
                      placeholder={pageData.strategy.placeholder}
                      type={pageData.strategy.type}
                      value={formik.values.strategy}
                      onChange={(value) =>
                        formik.setFieldValue("strategy", value)
                      }
                    />
                    <MultiSelect
                      title="hello"
                      label={pageData.joinempty.label}
                      data-toggle="tooltip"
                      name={pageData.joinempty.name}
                      isMulti={false}
                      options={joinempty}
                      placeholder={pageData.joinempty.placeholder}
                      type={pageData.joinempty.type}
                      value={formik.values.joinempty}
                      onChange={(value) =>
                        formik.setFieldValue("joinempty", value)
                      }
                    />
                    <MultiSelect
                      label={pageData.leaveempty.label}
                      name={pageData.leaveempty.name}
                      isMulti={false}
                      options={leavewhenempty}
                      placeholder={pageData.leaveempty.placeholder}
                      type={pageData.leaveempty.type}
                      value={formik.values.leavewhenempty}
                      onChange={(value) =>
                        formik.setFieldValue("leavewhenempty", value)
                      }
                    />
                    <Toggle
                      data-toggle="tooltip"
                      title={pageData.announcement.title}
                      label={pageData.announcement.label}
                      name={pageData.announcement.name}
                      type={pageData.announcement.type}
                      value={formik.values.announcementstatus}
                      defaultChecked={formik.values.announcementstatus}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "announcementstatus",
                          e.target.checked
                        )
                      }
                    />
                    {formik.values.announcementstatus && (
                      <TextField
                        title={pageData.announpath.title}
                        data-toggle="tooltip"
                        label={pageData.announpath.label}
                        placeholder={pageData.announpath.placeholder}
                        type={pageData.announpath.type}
                        name={pageData.announpath.name}
                      />
                    )}
                    <Toggle
                      data-toggle="tooltip"
                      title={pageData.callwait.title}
                      label={pageData.callwait.label}
                      name={pageData.callwait.name}
                      type={pageData.callwait.type}
                      value={formik.values.ringinuse}
                      defaultChecked={formik.values.ringinuse}
                      onChange={(e) =>
                        formik.setFieldValue("ringinuse", e.target.checked)
                      }
                    />
                    <Toggle
                      title={pageData.autofill.title}
                      data-toggle="tooltip"
                      label={pageData.autofill.label}
                      name={pageData.autofill.name}
                      type={pageData.autofill.type}
                      value={formik.values.autofill}
                      defaultChecked={formik.values.autofill}
                      onChange={(e) =>
                        formik.setFieldValue("autofill", e.target.checked)
                      }
                    />
                    <Toggle
                      data-toggle="tooltip"
                      title={pageData.reporthold.title}
                      label={pageData.reporthold.label}
                      name={pageData.reporthold.name}
                      type={pageData.reporthold.type}
                      value={formik.values.reportholdtime}
                      defaultChecked={formik.values.reportholdtime}
                      onChange={(e) =>
                        formik.setFieldValue("reportholdtime", e.target.checked)
                      }
                    />
                    <hr />
                    <div className="d-flex">
                      <TextField
                        title={pageData.musichold.title}
                        data-toggle="tooltip"
                        label={pageData.musichold.label}
                        placeholder={pageData.musichold.placeholder}
                        type={pageData.musichold.type}
                        name={pageData.musichold.name}
                        disabled={props?.data?.paremsData.action === "UPDATE"}
                      />
                      <MultiSelect
                        title={pageData.audioplay.title}
                        label={pageData.audioplay.label}
                        name={pageData.audioplay.name}
                        isMulti={true}
                        formik={formik}
                        options={playlist}
                        placeholder={pageData.audioplay.placeholder}
                        type={pageData.audioplay.type}
                        value={formik.values.entry}
                        onChange={(value) => {
                          console.log("vvvvv: ", value);
                          formik.setFieldValue("entry", value);
                        }}
                      />

                      <div className="row ml-6 mt-4">
                        <div className="col-2 ">
                          <button
                            type={pageData.savebtn.type}
                            className="btn btn-primary btn-sm m-2  "
                          >
                            {pageData.savebtn.label}
                          </button>
                        </div>
                        <div className="col-7" style={{ marginLeft: "-90px" }}>
                          <Link
                            to="MNU_MANAGE_DIALER_Q"
                            className="btn btn-primary btn-sm m-2"
                            onClick={(e) => {
                              dispatch(
                                setParems({ data: "MNU_MANAGE_DIALER_Q" })
                              );
                            }}
                          >
                            {pageData.cancelbtn.label}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
export default MasterDailerQueue;
