import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import api from "../../utils/api";
import { useSelector } from "react-redux";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    gridClasses,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import toast from "react-hot-toast";
import Tooltip from '@mui/material/Tooltip';
import DaynmicApicall from "../../utils/function";
import * as Yup from "yup";
import { Dialog, DialogContent, Button, Chip, Grid, FormControl, CircularProgress } from '@mui/material';


export default function ManageDataPreprationForCamp(props) {
    const { userInfo } = useSelector((state) => state?.user?.value);
    const { campdetail } = useSelector((state) => state.campaign);
    const [pageData, setPageData] = useState('');
    const [searchdata, setSearchdata] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [condition, setCondition] = useState(50);
    const [dataCount, setTotalDataCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const [initialData, setInitialData] = useState({
        quearyFields: [
            {
                Fields: "",
                Operator: "",
                value: "",
            }
        ]
    });


    let allCamps = [];
    if (campdetail && campdetail.length > 0) {
        allCamps = campdetail.map((item) => {
            if (item.ctype !== 'PRI') {
                return {
                    value: item.campid,
                    label: item.campname,
                };
            } else {
                return null;
            }
        }).filter(item => item !== null);
    }
    

    // const pageData = {
    //     "filters": [
    //         {
    //             "value": "age",
    //             "label": "Age",
    //             "type": "text",
    //             "fieldtype": "number"
    //         },
    //         {
    //             "value": "food_habit",
    //             "label": "Food Habit",
    //             "type": "multi",
    //             "fieldtype": "multi",
    //             "multi": true,
    //             "option": [
    //                 { value: "Vegetarian", label: "Vegetarian" },
    //                 { value: "Non-Vegetarian", label: "Non-Vegetarian" },
    //             ]
    //         },
    //         {
    //             "value": "weight",
    //             "label": "Weight",
    //             "type": "text",
    //             "fieldtype": "number",
    //         },
    //         {
    //             "value": "lot_date",
    //             "label": "Data Upload Date",
    //             "type": "text",
    //             "fieldtype": "date",
    //         },
    //         {
    //             "value": "bmi",
    //             "label": "BMI",
    //             "type": "text",
    //             "fieldtype": "text",
    //         },
    //         {
    //             "value": "localcity",
    //             "label": "Local City",
    //             "type": "multi",
    //             "fieldtype": "multi",
    //             "multi": true,
    //             "option": [
    //                 { value: "Ahamdabad", label: "Ahamdabad" },
    //                 { value: "Surat", label: "Surat" },
    //             ]
    //         },
    //         {
    //             "value": "marital_status",
    //             "label": "Marital",
    //             "type": "multi",
    //             "fieldtype": "multi",
    //             "multi": true,
    //             "option": [
    //                 { value: "Married", label: "Married" },
    //                 { value: "Unmarried", label: "Unmarried" },
    //             ]

    //         },
    //         {
    //             "value": "gender",
    //             "label": "Gender",
    //             "type": "multi",
    //             "fieldtype": "multi",
    //             "multi": true,
    //             "option": [
    //                 { value: "Male", label: "Male" },
    //                 { value: "Female", label: "Female" },
    //             ]
    //         }
    //     ],
    //     "Operator": [
    //         {
    //             "value": "<=",
    //             "label": "<="
    //         },
    //         {
    //             "value": "=",
    //             "label": "="
    //         },
    //         {
    //             "value": ">=",
    //             "label": ">="
    //         },
    //         {
    //             "value": "in",
    //             "label": "in"
    //         },
    //     ],
    //     "columns": [
    //         { field: "cust_id", headerName: "Customer ID", width: 150, className: 'text-center' },
    //         { field: "volunteer_no", headerName: "Volunteer No", width: 150 },
    //         { field: "volunteer_name", hbmieaderName: "Volunteer Name", width: 150 },
    //         { field: "age", headerName: "Age", width: 100 },
    //         { field: "bmi", headerName: "BMI", width: 100 },
    //         { field: "height", headerName: "Height", width: 100 },
    //         { field: "weight", headerName: "Weight", width: 100 },
    //         { field: "cust_language", headerName: "Language", width: 150 },
    //         { field: "contact_no", headerName: "Phone Number", width: 150 },
    //         { field: "gender", headerName: "Gender", width: 150 },

    //     ],
    //     "buttons": {
    //         "addfilter": {
    //             "label": "Add more filter",
    //             "tooltip": "Click on this button to add more filters",
    //             "variant": "contained",
    //             "color": "primary",
    //             "size": "small",
    //             "type": "submit"
    //         },
    //         "removefilter": {
    //             "label": "Search",
    //             "tooltip": "Click on this button to remove filter",
    //             "variant": "contained",
    //             "color": "error",
    //             "size": "small",
    //             "type": "submit"
    //         },
    //         "searchdata": {
    //             "label": "Search",
    //             "tooltip": "Click on search button to search a data",
    //             "variant": "contained",
    //             "color": "success",
    //             "size": "small",
    //             "type": "submit"
    //         },
    //         "reset": {
    //             "label": "Reset form",
    //             "tooltip": "Reset the Above from",
    //             "variant": "contained",
    //             "color": "success",
    //             "size": "small",
    //             "type": "submit"
    //         }
    //     }
    // }




    const onSubmit = async (values, actions) => {
        try {
            setTotalDataCount(0)
            const Info = api.post(
                "/services/managedatadreprationforcamp",
                values,
                { headers: { Authorization: userInfo.data[0].UserToken } }
            );

            toast.promise(Info, {
                success: (resolvedInfo) => resolvedInfo.data.message,
                error: (err) => {
                    return (

                        err?.response?.data?.errors ??
                        err?.response?.data?.message ??
                        err?.message ??
                        "OOPs something went wrong."
                    );
                },
            }).then((Info) => {
                setSearchdata(Info.data.data);
                setTotalDataCount(Info.data.data[0].row_count);
                setCondition(Info.data.condition);
            })
        } catch (error) {
            console.error("Error in onSubmit:", error);
        }
    };


    const PrepareCampDataOnSubmit = async (values, actions) => {
        try {
            setOpenModal(true); // Open the modal when API request starts
            const postData = {
                campid: values.campid.value,
                condition: condition,
                campname: values.campid.label
            };
            const Info = api.post(`/services/managemulticampdata`, postData, {
                headers: { Authorization: userInfo.data[0].UserToken },
            })
            toast.promise(Info, {
                success: (resolvedInfo) => resolvedInfo.data.message,
                error: (err) => {
                    return (
                        err?.response?.data?.errors ??
                        err?.response?.data?.message ??
                        err?.message ??
                        "OOPs something went wrong."
                    );
                },
            }).then((data) => {
                setOpenModal(false);
            })
        } catch (error) {
            console.error("Error in PrepareCampDataOnSubmit:", error);
            setOpenModal(false);
        }
    };

    const handleCloseDialog = (event, reason) => {
        if (reason !== "backdropClick") {
            handleClose(event, reason);
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };




    function CustomToolbar() {
        return (
            <Stack direction="row" spacing={0.5} m={1}>
                <GridToolbarContainer className={gridClasses.toolbarContainer}>
                    <GridToolbarExport />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                </GridToolbarContainer>
            </Stack >
        );
    }


    async function pageInfo() {
        await DaynmicApicall(`/appuser/getcomponetbyid/${props.activemenu.keypointer}/${props.activemenu.componentid}`, '', 'get', userInfo.data[0].UserToken).then((data) => {
            setPageData(data[0].DATA);
        })
            .catch((error) => {
                console.log("ERROR: ", error)
                toast.error(
                    error.response.message ??
                    error.message ??
                    "OOPs, Something went wrong."
                );
            });
    }

    useEffect(async () => {
        await pageInfo();
    }, []);


    return (
        <>
            {pageData && <div>
                <Box className="card bg-dark">
                    <div className="col-xl-12 ">
                        <div className="row row-cols-md-2 m-2 p-2 row-cols-lg-2 row-cols-xl-2 font-weight-bold">
                            <span className="text-left text-light">Manage Data Prepration For Camp</span>
                            <span className="text-right">
                            </span>
                        </div>
                    </div>
                </Box>

                <Box className="card mt-3 p-2">
                    <Formik
                        initialValues={initialData}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <>
                                <Box className="card-body">
                                    {formik.values.quearyFields.map((exp, index) => (
                                        <Grid container spacing={2} sx={{ my: 1, display: 'flex' }} key={index}>
                                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                                <Select
                                                    options={pageData.filters}
                                                    placeholder="Select Fields"
                                                    isSearchable
                                                    onChange={(selectedOption) => formik.setFieldValue(`quearyFields[${index}].Fields`, selectedOption || '')}
                                                />
                                            </FormControl>

                                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                                                <Select
                                                    options={pageData.Operator}
                                                    placeholder="Operator"
                                                    isSearchable
                                                    onChange={(selectedOption) => formik.setFieldValue(`quearyFields[${index}].Operator`, selectedOption?.value || '')}
                                                />
                                            </FormControl>
                                            {formik.values.quearyFields[index].Fields.type === 'text' && (
                                                <>
                                                    {/* <FormControl sx={{ m: 1, minWidth: 80 }}>
                                                        <TextField
                                                            label="MIN"
                                                            variant={formik.values.quearyFields[index].Fields.fieldtype !== 'date' ? "standard" : "filled"}
                                                            type={formik.values.quearyFields[index].Fields.fieldtype}
                                                            fullWidth
                                                            name={`quearyFields[${index}].min`}
                                                            onChange={formik.handleChange}
                                                            value={exp.min}
                                                        />
                                                    </FormControl> */}
                                                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                                                        <Tooltip title="{pageData.buttons.searchdata.tooltip}" placement="top-start">
                                                            <TextField label="Value"
                                                                // variant="standard"
                                                                variant={formik.values.quearyFields[index].Fields.fieldtype !== 'date' ? "standard" : "filled"}
                                                                fullWidth
                                                                type={formik.values.quearyFields[index].Fields.fieldtype}
                                                                name={`quearyFields[${index}].value`}
                                                                onChange={formik.handleChange}
                                                                value={exp.value}
                                                            />
                                                        </Tooltip>
                                                    </FormControl>
                                                </>
                                            )}
                                            {formik.values.quearyFields[index].Fields.type === 'multi' && (
                                                <>
                                                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                                                        <Select
                                                            options={formik.values.quearyFields[index].Fields.option}
                                                            isMulti={formik.values.quearyFields[index].Fields.multi}
                                                            placeholder="Select values"
                                                            isSearchable
                                                            onChange={(selectedOptions) => {
                                                                const selectedValues = selectedOptions.length > 0
                                                                    ? selectedOptions.map(option => option.value)
                                                                    : null;

                                                                formik.setFieldValue(`quearyFields[${index}].value`, selectedValues || []);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </>
                                            )}

                                            {index === formik.values.quearyFields.length - 1 && (
                                                <>
                                                    <Tooltip title={pageData.buttons.addfilter.tooltip} placement="top-start">
                                                        <Button
                                                            sx={{ m: 1 }}
                                                            variant={pageData.buttons.addfilter.variant}
                                                            color={pageData.buttons.addfilter.color}
                                                            size={pageData.buttons.addfilter.size}
                                                            onClick={() => formik.setValues({ ...formik.values, quearyFields: [...formik.values.quearyFields, { Fields: '', Operator: '' }] })}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                            {pageData.buttons.addfilter.label}
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title={pageData.buttons.removefilter.tooltip} placement="top-start">
                                                        <Button
                                                            sx={{ m: 1 }}
                                                            variant={pageData.buttons.removefilter.variant}
                                                            color={pageData.buttons.removefilter.color}
                                                            size={pageData.buttons.removefilter.size}
                                                            onClick={() => {
                                                                if (formik.values.quearyFields.length > 1) {
                                                                    const updatedFilters = formik.values.quearyFields.slice(0, -1);
                                                                    formik.setValues({ ...formik.values, quearyFields: updatedFilters });
                                                                }
                                                            }}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </Button>
                                                    </Tooltip>

                                                </>
                                            )}
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <Tooltip title={pageData.buttons.searchdata.tooltip} placement="top-start">
                                            <Button
                                                sx={{ m: 1 }}
                                                variant={pageData.buttons.searchdata.variant}
                                                color={pageData.buttons.searchdata.color}
                                                type={pageData.buttons.searchdata.type}
                                                onClick={formik.handleSubmit}
                                            >
                                                {pageData.buttons.searchdata.label}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={pageData.buttons.reset.tooltip} placement="top-start">
                                            <Button
                                                sx={{ m: 1, background: "#444" }}
                                                variant={pageData.buttons.reset.variant}
                                                color={pageData.buttons.reset.color}
                                                type={pageData.buttons.reset.type}
                                                onClick={formik.resetForm}  // Add this line
                                            >
                                                {pageData.buttons.reset.label}
                                            </Button>
                                        </Tooltip>

                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Formik>

                    {searchdata && dataCount > 0 && (
                        <div className="my-4">
                            <div className="card card-body border-light shadow">
                                <div className="table-settings mb-4">
                                    <Formik
                                        initialValues={{ campid: "" }}
                                        validationSchema={Yup.object().shape({
                                            campid: Yup.object().required("Camp Id is required"), // Adjusted validation for string input
                                        })}
                                        onSubmit={PrepareCampDataOnSubmit}
                                    >
                                        {(formik) => (
                                            <form onSubmit={formik.handleSubmit}>
                                                <Grid container spacing={2} sx={{ display: 'flex' }}>
                                                    <Grid item xs={6}>
                                                        <Chip label={`Count: ${dataCount}`} color="success" />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                                                            <Select
                                                                options={allCamps}
                                                                placeholder="Campaign"
                                                                isSearchable
                                                                onChange={(selectedOption) =>
                                                                    formik.setFieldValue("campid", selectedOption || "")
                                                                }
                                                                onBlur={formik.handleBlur}
                                                                // value={formik.values.campid}
                                                            />
                                                        </FormControl>
                                                        <Button
                                                            sx={{ m: 1 }}
                                                            variant="contained"
                                                            color="success"
                                                            type="submit"
                                                        >
                                                            Prepare Campaign Data
                                                        </Button>
                                                        {formik.touched.campid && formik.errors.campid ? (
                                                            <div style={{ color: "red", marginLeft: "10px" }}>{formik.errors.campid}</div>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>

                                    <div className="my-2">
                                        <DataGrid
                                            getRowId={(r) => r.cuid}
                                            rows={searchdata ? searchdata : []}
                                            columns={pageData.columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={(newPageSize) =>
                                                setPageSize(newPageSize)
                                            }
                                            rowsPerPageOptions={[50, 70, 100]}
                                            // onFilterModelChange={onFilterChange}
                                            autoHeight={true}
                                            className="bg-white"
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            density="compact"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Dialog
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open={openModal}
                        onClose={handleCloseDialog}
                    >
                        <DialogContent>
                            <CircularProgress color="primary" />
                            <p>Data is being processed...</p>
                        </DialogContent>
                    </Dialog>
                </Box>
            </div>}
        </>
    );
}