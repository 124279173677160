export default function DashboardFooter() {
  const PageData = {
    // title:" © 2023 veeda clinical research Ltd | All rights reserved"
    title: "Copyright © 2023-2024 payZorro",
    Disclaimer: "All Rights Reserved. The information in this email is for the use of the individual or entity to whom it is addressed and others authorized to receive it. It may contain confidential or legally privileged information. If you are not the intended recipient, you are hereby notified that any disclosure, copying, distribution or taking any action relying on the contents of this information is strictly prohibited; it may be unlawful. If you have received this email by mistake, please notify me immediately by replying to this email and then delete it from your system"
  }
  return (
    <footer className="position-auto bg-dark text-white  text-md-center mt-2rem p-1" >
      <p className="text-muted mt-2">
        {PageData.title}
      </p>
    </footer>
  );
}
